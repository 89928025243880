import connect from 'tools/connect';

import Loader from '../Loader';
// import Error from '../Error';
// import TestOperation from './UploadReceiptTemplate';
const operations = {
    // ['TestOperation']: TestOperation,
};

// eslint-disable-next-line react/prop-types
function Operation({operation}) {
    // if (operation.error) return <Error />;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, react/prop-types
    const CurrentOperation = operations[operation.name];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, react/prop-types
    return operation.isLoading ? <Loader /> : <CurrentOperation />;
}

export default connect(
    ({
        wizard: {
            current: {operation},
        },
    }) => ({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        operation,
    }),
)(Operation);
