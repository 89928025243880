import Button from 'atol-atoms/Button2';
import Icon from 'atol-atoms/Icon';
import {Link} from 'react-router-dom';
import {DEVICES_VIEW} from 'constants/routes';
import Typography from 'atol-atoms/Typography';
import {Bem} from 'atol-atoms/tools';
import connect from 'tools/connect';
import {MenuProps} from '../types';
import './style.scss';

const b_ = Bem('TopPanel');

function TopPanel({
    atolIdUser,
    atolIdLogout,
    setIsMenuVisible,
    isMenuVisible,
    title,
}: MenuProps) {
    return (
        <div className={b_()}>
            <div className={b_('panelWrapper')}>
                <div className={b_('titleWrapper')}>
                    {title && <Link to={DEVICES_VIEW}><Icon icon="chevron-left" status="default" size={16} /></Link>}
                    {title && <Typography type="h2">{title}</Typography>}
                </div>
                <div className={b_('userWrapper')}>
                    <Typography
                        type="strong"
                        className={b_('user')}
                        bddmark="имя пользователя"
                    >
                        {atolIdUser?.name || atolIdUser?.email}
                    </Typography>
                    <Button
                        type="link-secondary"
                        bddmark="логаут"
                        className={b_('logout')}
                        onClick={atolIdLogout}
                        aria-label="Выход"
                        size="l"
                    >
                        <Icon icon="exit" />
                    </Button>
                </div>
                <div className={b_('logoWrapper')}>
                    <div className={b_('logo')}>
                        <Icon icon="atol-a" status="white" size={32} />
                        {/* <img src={`${process.env.PUBLIC_URL}/images/newyearlogo.svg`} alt="logo" /> */}
                    </div>
                    <div className={b_('logoTitle')}>TMS</div>
                </div>
                <Button
                    className={b_('toggleMenu')}
                    type="link-secondary"
                    icon={isMenuVisible ? 'close' : 'cards'}
                    onClick={() => setIsMenuVisible(!isMenuVisible)}
                    size="l"
                >
                    <Icon icon={isMenuVisible ? 'close' : 'cards'} />
                </Button>
            </div>
        </div>
    );
}

export default connect(({auth: {atolIdUser, atolIdLogout}, common: {topPanel: {title}}}) => ({
    atolIdUser,
    atolIdLogout,
    title,
}))(TopPanel);
