import {
    DEVICES_VIEW,
    ROUTER_SCHEME,
    RouteConfig,
} from 'constants/routes';

const menuData: RouteConfig[] = [
    ROUTER_SCHEME[DEVICES_VIEW],
];
export default menuData;
