/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// @ts-nocheck
import {types, flow, getRoot} from 'mobx-state-tree';
import api from 'api';
import {IDeviseNetworkInfo} from 'types/device';

export const Network = types
    .model('Network', {
        id: types.maybeNull(types.number),
        osType: types.maybeNull(types.string),
        osVersion: types.maybeNull(types.string),
        osBuildNumber: types.maybeNull(types.string),
        freeSpaceStorageInPercent: types.maybeNull(types.union(types.number, types.string)),
        typeConnection: types.maybeNull(types.string),
        ipAddress: types.maybeNull(types.string),
        netmask: types.maybeNull(types.string),
        dns_1: types.maybeNull(types.string),
        dns_2: types.maybeNull(types.string),
        gateway: types.maybeNull(types.string),
        isDhcp: types.maybeNull(types.boolean),
        mac: types.maybeNull(types.string),
        wifiSsid: types.maybeNull(types.string),
        wifiLevel: types.maybeNull(types.union(types.number, types.string)),
        wifiIsWifiApEnabled: types.maybeNull(types.boolean),
        wifiChannelWidth: types.maybeNull(types.union(types.number, types.string)),
        wifiCapabilities: types.maybeNull(types.string),
        trafficEthernetRx: types.maybeNull(types.union(types.number, types.string)),
        trafficEthernetTx: types.maybeNull(types.union(types.number, types.string)),
        trafficWifiTx: types.maybeNull(types.union(types.number, types.string)),
        trafficWifiRx: types.maybeNull(types.union(types.number, types.string)),
        trafficMobileTx: types.maybeNull(types.union(types.number, types.string)),
        trafficMobileRx: types.maybeNull(types.union(types.number, types.string)),
    });

const NetworkStore = types.model('NetworkStore', {
    data: types.optional(Network, {}),
    isLoading: types.optional(types.boolean, false),
    error: types.optional(types.frozen(), null),
}).actions((self) => {
    const model = self;
    const setError = getRoot(self).apiError.set as (code: number, message: string)=> void;
    const refreshToken = getRoot(self).auth.refreshToken as ()=> void;

    const get = flow(function* ajax(serialNumber: string) {
        model.isLoading = true;
        model.error = null;
        try {
            const data: IDeviseNetworkInfo = yield api.get<IDeviseNetworkInfo>({path: `/device_network/?serial_number=${serialNumber}`, responseFormat: 'json', setError, refreshToken});
            model.data = data;
        } catch (err) {
            try {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                const error = JSON.parse(err.message);
                model.error = error;
            } catch (e) {
                model.error = {code: '', message: 'На сервере произошла ошибка, повторите попытку позже'};
            }
        }
        model.isLoading = false;
    });

    return {get};
});

export default NetworkStore;
