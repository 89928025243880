import {
    emailRegexp,
    innRegexp,
    kppRegexp,
    macAddressRegexp,
    fioRegexp,
    passwordUppercaseRegexp,
    passwordNumberRegexp,
    passwordSymbolRegexp,
    passwordCyrillicRegexp,
    urlRegexp,
} from 'constants/regexp';

export const notEmpty = (value: string | []) => Boolean(
    value
            && (typeof value === 'string'
                ? value.trim().length > 0
                : value.length > 0),
);
export const isEmailValid = (value: string): boolean => emailRegexp.test(value);

export const isSerialNumberValid = (serialNumber: string) => serialNumber
    && serialNumber.length === 14
    && serialNumber.slice(0, 3) === '001';
export const isInnValid = (value: string) => innRegexp.test(value);
export const isKppValid = (value: string) => kppRegexp.test(value);
export const isMacAddressValid = (mac: string) => macAddressRegexp.test(mac);
export const isFioValid = (value: string) => fioRegexp.test(value);
export const isPasswordHasNotCyrillic = (value: string) => passwordCyrillicRegexp.test(value);
export const isPasswordHasUppercase = (value: string) => passwordUppercaseRegexp.test(value);
export const isPasswordHasNumber = (value: string) => passwordNumberRegexp.test(value);
export const isPasswordHasSymbol = (value: string) => passwordSymbolRegexp.test(value);
export const isUrlAddressValid = (address: string) => urlRegexp.test(address);
