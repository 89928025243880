import Icon from 'atol-atoms/Icon';
import Spacer from 'atol-atoms/Spacer';
import {Bem} from 'atol-atoms/tools';
import './style.scss';
import {LeftMenuProps} from '..';

const b_ = Bem('MobileHeader');

export default function MobileHeader({atolIdUser, atolIdLogout}: LeftMenuProps['auth']) {
    return (
        <div className={b_()}>
            <div className={b_('userName')}>
                {atolIdUser.name || atolIdUser.email}
            </div>
            <Spacer size={16} />
            <button type="button" className={b_('logout')} onClick={atolIdLogout}>
                <Icon icon="exit" className={b_('logoutIcon')} />
                <span className={b_('logoutText')}>Выйти</span>
            </button>
        </div>
    );
}
