import {types} from 'mobx-state-tree';

const GroupFilterItem = types
    .model('GroupFilterItem', {
        label: types.string,
        value: types.union(types.string, types.number, types.boolean),
        selected: types.optional(types.boolean, false),
        active: types.optional(types.boolean, false),
        groupId: types.number,
        isUserGroup: types.optional(types.boolean, false),
        parentId: types.maybeNull(types.number),
    })
    .actions((self) => {
        const groupFilterItem = self;
        const toggle = () => {
            const state = !groupFilterItem.selected;
            groupFilterItem.selected = state;
        };

        const set = (state: boolean) => {
            groupFilterItem.selected = state;
        };

        const add = (state: boolean) => {
            groupFilterItem.active = state;
        };

        return {toggle, set, add};
    });

export default GroupFilterItem;
