import {DEVICES_VIEW} from 'constants/routes';
import Keycloak, {KeycloakInstance} from 'keycloak-js';
import config from 'configs';

class KeycloakService {
    private keycloak: KeycloakInstance;

    constructor() {
        this.keycloak = new Keycloak({
            url: config.keycloakApi,
            realm: 'ATOL-ID-External',
            clientId: 'tms_client',
        });
    }

    init = async (): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            this.keycloak
                .init({
                    onLoad: 'check-sso',
                    checkLoginIframe: false,
                })
                .then((authenticated) => {
                    if (authenticated) {
                        localStorage.setItem('refreshToken', this.keycloak.refreshToken || '');
                        localStorage.setItem('token', this.keycloak.token || '');
                        resolve(authenticated);
                    } else {
                        reject();
                    }
                })
                .catch((error) => {
                    console.error('Keycloak initialization error:', error);
                    reject();
                });
        });
    };

    login = async (): Promise<void> => {
        try {
            this.keycloak.redirectUri = `${window.location.origin}${DEVICES_VIEW}`;
            await this.keycloak.login();
        } catch (error) {
            console.error('Failed to login: ', error);
        // Handle the error appropriately
        }
    };

    logout = async (): Promise<void> => {
        try {
            await this.keycloak.logout();
        } catch (error) {
            console.error('Failed to login: ', error);
        // Handle the error appropriately
        }
    };

    refreshToken = async (): Promise<void> => {
        if (this.keycloak) {
            await this.keycloak.updateToken(30).then(() => {
                localStorage.setItem('token', this.keycloak.token);
                localStorage.setItem('refreshToken', this.keycloak.refreshToken);
            }).catch((error) => {
                if (error) {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    this.logout();
                }
            });
        }
    };

    getToken = (): string | undefined => {
        return this.keycloak.token;
    };

    getRefreshToken = (): string | undefined => {
        return this.keycloak.refreshToken;
    };

    getUser = () => {
        return this.keycloak.tokenParsed;
    };

    getUserData = () => {
        return this.keycloak.profile;
    };
}

export default KeycloakService;
