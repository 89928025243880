import {IconType} from 'atol-atoms/Icon';

export const AUTH_ROUTE = '/auth';
export const HOME = '/home';
export const DEVICES_VIEW = '/devices/';
export const DEVICES_CCT_VIEW = '/devices/cct';
export const DEVICE_DETAILS_BASE = '/devices/:id/base/';
export const DEVICE_DETAILS_APPLICATIONS = '/devices/:id/applications/';
export const DEVICE_DETAILS_NETWORK = '/devices/:id/network/';
export const DEVICE_DETAILS_NETWORK_CONNECTION = 'connection';
export const DEVICE_DETAILS_NETWORK_WIFI = 'wifi';
export const DEVICE_DETAILS_NETWORK_TRANSFER = 'transfer';
export const DEVICE_DETAILS_ADDITIONAL = '/devices/:id/additional/';
export const DEVICE_DETAILS_CONFIGURATIONS = '/devices/:id/configurations/';
export const DEVICE_DETAILS = '/devices/:id';
export const DEVICES_PDT_VIEW = '/devices/pdt';
export const CCT_VIEW = 'cct';
export const PDT_VIEW = 'pdt';
export const PROFILE_ROUTE = '/profile-section';

export interface RouteConfig {
    container?: string;
    pageTitle?: string;
    title?: string;
    icon?: IconType;
    to?: string;
    activeRoutes?: string[];
    excludeActiveRoutes?: string[],
    links?: RouteLink[],
    access?: AccessConfig,
}

export interface RouteLink {
    title?: string;
    icon?: IconType;
    to?: string;
    access?: AccessConfig;
    onClick?: ()=> void;
    href?: string;
    excludeActiveRoutes?: string[],
    activeRoutes?: string[];
}
interface AccessConfig {
    scope?: string[];
    permissions?: string[]
}

export interface RouterScheme {
    [key: string]: RouteConfig;
}

export const ROUTER_SCHEME: RouterScheme = {
    [HOME]: {
        container: 'StartPage',
        pageTitle: 'Стартовая',
        title: 'Стартовая',
        icon: 'favorite',
        to: HOME,
    },

    [DEVICE_DETAILS]: {
        container: 'DeviceDetails',
        pageTitle: 'Устройство',
        title: 'Устройство',
        icon: 'cashbox',
        to: DEVICE_DETAILS,
        activeRoutes: ['/deviceDetails/:id'],
    },

    [DEVICES_VIEW]: {
        container: 'Devices',
        pageTitle: 'Устройства',
        title: 'Устройства',
        icon: 'device',
        to: DEVICES_VIEW,
        activeRoutes: ['/devices'],
    },

    [DEVICES_CCT_VIEW]: {
        pageTitle: 'ККТ',
        title: 'ККТ',
        icon: 'cashbox',
        to: DEVICES_CCT_VIEW,
        activeRoutes: [DEVICES_CCT_VIEW],
    },

    [DEVICE_DETAILS_BASE]: {
        pageTitle: 'Основное',
        title: 'Основное',
        icon: 'cashbox',
        to: DEVICE_DETAILS_BASE,
        activeRoutes: [DEVICE_DETAILS_BASE],
    },

    [DEVICE_DETAILS_APPLICATIONS]: {
        pageTitle: 'Приложения',
        title: 'Приложения',
        icon: 'cashbox',
        to: DEVICE_DETAILS_APPLICATIONS,
        activeRoutes: [DEVICE_DETAILS_APPLICATIONS],
    },

    [DEVICE_DETAILS_NETWORK]: {
        pageTitle: 'Сеть',
        title: 'Сеть',
        icon: 'cashbox',
        to: DEVICE_DETAILS_NETWORK,
        activeRoutes: [DEVICE_DETAILS_NETWORK],
    },

    [DEVICE_DETAILS_ADDITIONAL]: {
        pageTitle: 'Дополнительно',
        title: 'Дополнительно',
        icon: 'cashbox',
        to: DEVICE_DETAILS_ADDITIONAL,
        activeRoutes: [DEVICE_DETAILS_ADDITIONAL],
    },

    [DEVICE_DETAILS_CONFIGURATIONS]: {
        pageTitle: 'Конфигурации',
        title: 'Конфигурации',
        icon: 'cashbox',
        to: DEVICE_DETAILS_CONFIGURATIONS,
        activeRoutes: [DEVICE_DETAILS_CONFIGURATIONS],
    },

    [DEVICES_PDT_VIEW]: {
        pageTitle: 'ТСД',
        title: 'ТСД',
        icon: 'cashbox',
        to: DEVICES_PDT_VIEW,
        activeRoutes: [DEVICES_PDT_VIEW],
    },
    [PROFILE_ROUTE]: {
        container: 'Profile',
        pageTitle: 'Профиль',
        title: 'Профиль',
        icon: 'profile',
        to: PROFILE_ROUTE,
    },

    [AUTH_ROUTE]: {
        pageTitle: 'Вход',
    },
};
