/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {types, flow, getRoot} from 'mobx-state-tree';
import api from 'api';
import {IDeviceApplicationInfo} from 'types/device';
import List from 'components/Table/list';
import {IList} from 'types';

export const Application = types
    .model('Application', {
        id: types.maybeNull(types.number),
        name: types.maybeNull(types.union(types.string, types.undefined)),
        label: types.maybeNull(types.string),
        isSystem: types.maybeNull(types.boolean),
        installTime: types.maybeNull(types.string),
        updateTime: types.maybeNull(types.string),
        version: types.maybeNull(types.string),
        totalSize: types.maybeNull(types.union(types.number, types.string)),
    });

const ApplicationsStore = types.model('ApplicationsStore', {
    data: types.optional(List(Application), {}),
    error: types.optional(types.frozen(), null),
}).actions((self) => {
    const model = self;
    const refreshToken = getRoot(self).auth.refreshToken as ()=> void;

    const get = flow(function* ajax(serialNumber: string) {
        model.data.isLoading = true;
        try {
            const data = yield api.get<IList<IDeviceApplicationInfo>>({path: `/device_applications/?serial_number=${serialNumber}`, refreshToken});

            model.data.items = data.data;
            model.data.count = data.count;
            model.error = null;
        } catch (err) {
            try {
                const error = JSON.parse(err.message);
                model.data.setError(error.message);
                model.error = error;
            } catch (e) {
                model.data.setError('Перезагрузите страницу или повторите попытку позже');
            }
        }
        model.data.isLoading = false;
    });

    const init = () => {
        model.data.attach();
    };

    const resetListError = () => {
        model.error = null;
        model.data.resetListError();
        window.location.reload();
    };

    return {get, init, resetListError};
});

export default ApplicationsStore;
