export const INFORMATION_DTO10 = 'https://as-public.s3.atol.ru/public/static/driver/10.9.2.6.zip';
export const ATOL_SOFT = 'https://www.atol.ru/softdev';
export const PRESENTATION_FIRMWARE_5 = 'https://yadi.sk/i/eS1RM9FOPQWq0A';
export const SPEED_COMPARISON = 'https://www.youtube.com/watch?v=AVcZofJ79iY';
export const LANDING = 'https://ac.atol.ru';
export const QUESTIONNAIRE_URL = 'https://forms.gle/8CDRgupNNbXEzQvJ6';
export const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=ru.atol.connect';

export const HELP_ARTICLE_LINK = 'https://ac.atol.ru/app/v2/help/article';
export const HELP_ROLE_ARTICLE = HELP_ARTICLE_LINK + '/33';
export const HELP_ADD_CCT_ARTICLE = HELP_ARTICLE_LINK + '/14';
export const HUB_CLOSING_ARTICLE = HELP_ARTICLE_LINK + '/35';
export const HELP_CCT_ADDING_ARTICLE = HELP_ARTICLE_LINK + '/37';
export const HELP_ASSISTANT_CONNECTION_ARTICLE = HELP_ARTICLE_LINK + '/38';
export const HELP_REGISTER_CCT_ARTICLE = HELP_ARTICLE_LINK + '/39';
export const ATOL_TERMS_LINK = 'https://tms-public.s3.atol.ru/public/Polzovatelskoe_soglashenie_ATOL_TMS.pdf';

export const AC_HELP_CENTER = 'https://ac.atol.ru/app/v2/help';

export const FNS_ACCOUNT = 'http://lkul.nalog.ru';
