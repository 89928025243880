import GroupItem from 'atol-atoms/Menu/GroupItem';
import MenuItem from 'atol-atoms/Menu/MenuItem';
import {Link, useLocation} from 'react-router-dom';

import {IconType} from 'atol-atoms/Icon';
import {RouteLink, RouteConfig} from 'constants/routes';
import {Fragment} from 'react';

interface LeftPanelItemProps {
    menuData: RouteConfig[],
    isMobile: boolean,
    setIsMenuVisible: (isMobile: boolean)=> void,
}

export default function LeftPanelItem({
    menuData,
    isMobile,
    setIsMenuVisible,
}: LeftPanelItemProps) {
    const {pathname} = useLocation();

    const checkIsGroupActive = (group: RouteConfig): boolean => {
        if (pathname.includes(group.to)) return true;
        return (
            group.links
            && group.links.some((link: RouteLink) => {
                if (pathname.includes(link?.to)) return true;
                return (
                    link?.activeRoutes
                    && link?.activeRoutes.some((route) => pathname.includes(route))
                );
            })
        );
    };

    const getGroupIcon = (group: RouteConfig): IconType => {
        const icon = `${group.icon as string}${isMobile ? '' : '-thin'}`;
        return icon as IconType;
    };

    const isMenuItemActive = (link: RouteLink): boolean => {
        if (pathname.includes(link.to)) return true;
        if (
            link.excludeActiveRoutes
            && link.excludeActiveRoutes.some((route: string) => pathname.includes(route))
        ) {
            return false;
        }
        if (
            link.activeRoutes
            && link.activeRoutes.some((route: string) => pathname.includes(route))
        ) {
            return true;
        }
        return false;
    };

    const onMenuItemClick = () => {
        if (!isMobile) return;
        setIsMenuVisible(false);
    };

    return (
        <Fragment>
            {menuData.filter((group: RouteConfig) => group)
                .map((group: RouteConfig) => {
                    const isGroupActive = checkIsGroupActive(group);

                    if (group.to) {
                        return (
                            <Link to={group.to} key={group.title}>
                                <GroupItem
                                    icon={(getGroupIcon(group))}
                                    title={group.title}
                                    isActive={isGroupActive}
                                    className="LeftPanel__noWrap"
                                    onClick={onMenuItemClick}
                                />
                            </Link>
                        );
                    }

                    const filtredItems = group.links.filter(
                        (link: RouteLink) => link,
                    );
                    if (filtredItems.length === 0) return null;

                    return (
                        <GroupItem
                            key={group.title}
                            icon={getGroupIcon(group)}
                            title={group.title}
                            isActive={isGroupActive}
                            className="LeftPanel__GroupItem"
                        >
                            {filtredItems.map((link: RouteLink) => {
                                if (link.href) {
                                    return (
                                        <a
                                            key={link.title}
                                            href={link.href}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <MenuItem
                                                icon={link.icon}
                                                onClick={onMenuItemClick}
                                            >
                                                {link.title}
                                            </MenuItem>
                                        </a>
                                    );
                                }
                                if (link.to) {
                                    return (
                                        <Link key={link.title} to={link.to}>
                                            <MenuItem
                                                isActive={isMenuItemActive(link)}
                                                icon={link.icon}
                                                onClick={onMenuItemClick}
                                            >
                                                {link.title}
                                            </MenuItem>
                                        </Link>
                                    );
                                }
                                return (
                                    <MenuItem
                                        icon={link.icon}
                                        onClick={() => {
                                            link.onClick();
                                            onMenuItemClick();
                                        }}
                                        key={link.title}
                                    >
                                        {link.title}
                                    </MenuItem>
                                );
                            })}
                        </GroupItem>
                    );
                })}
        </Fragment>
    );
}
