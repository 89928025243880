import React from 'react';
import Block from 'atol-atoms/Block';
import Button from 'atol-atoms/Button2';
import Typography from 'atol-atoms/Typography';
import {Bem} from 'atol-atoms/tools';

import './styles.scss';
import Flex from 'atol-atoms/Flex';
import Spacer from 'atol-atoms/Spacer';
import IconStatus, {IconStatusColorType} from 'atol-atoms/IconStatus';
import {observer} from 'mobx-react-lite';
import errorConfig from './config';

const b_ = Bem('Error');

export type TErrorType = '400' | '401' | '402' | '403' | '404' | '500' | '503';

export type IErrorData = {
        code?: TErrorType,
        message?: string,
}
interface IError {
    type?: TErrorType;
    iconStatus?: IconStatusColorType;
    title?: string;
    description?: string;
    errorCode?: string;
    buttonLabel?: string;
    onClickButton?: ()=> void,
}

function Error({
    type,
    iconStatus = 'negative',
    title = 'Запрашиваемая страница не работает',
    description = 'Если ошибка повторится, напишите нам. Мы постараемся решить проблему в кратчайшие сроки.',
    errorCode = 'Ошибка 400',
    buttonLabel = 'Перезагрузить страницу',
    onClickButton,
}: IError) {
    const iconStatusValue = iconStatus || (type ? errorConfig[type]?.iconStatus : iconStatus);
    const titleValue = title || (type ? errorConfig[type]?.title : title);
    const descriptionValue = description || (type ? errorConfig[type]?.description : description);
    const errorCodeValue = errorCode || (type ? errorConfig[type]?.errorCode : errorCode);
    const buttonLabelValue = buttonLabel || (type ? errorConfig[type]?.buttonLabel : buttonLabel);
    const onClickButtonValue = onClickButton || (type ? errorConfig[type]?.onClickButton : onClickButton);

    return (
        <div className={b_('container')}>
            <div className={b_('blockWrapper')}>
                <Block padding={32}>
                    <Flex alignItems="center">
                        <IconStatus type="filled" icon="attention" status={iconStatusValue} />
                        <Spacer vertical size={8} />
                        <Typography align="center" type="strong">{titleValue}</Typography>
                    </Flex>
                    <Spacer size={10} />
                    <Typography block>{descriptionValue}</Typography>
                    <Spacer size={4} />
                    <Typography className={b_('errorMessage')} block>{errorCodeValue}</Typography>
                    {buttonLabelValue && (
                        <React.Fragment>
                            <Spacer size={10} />
                            <Flex justifyContent="flex-end">
                                <Button onClick={onClickButtonValue} type="negative">{buttonLabelValue}</Button>
                            </Flex>
                        </React.Fragment>
                    )}

                </Block>
            </div>
        </div>
    );
}

export default observer(Error);
