import {types} from 'mobx-state-tree';

const User = types.model('User', {
    adminName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    hasFastReg: types.maybeNull(types.boolean),
    hasCloudTips: types.maybeNull(types.boolean),
    id: types.maybeNull(types.number),
    isAtol: types.maybeNull(types.boolean),
    issuperuser: types.maybeNull(types.boolean),
    login: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    orgType: types.maybeNull(types.string),
    organizationAddress: types.maybeNull(types.string),
    organizationComment: types.maybeNull(types.string),
    organizationEmail: types.maybeNull(types.string),
    organizationInn: types.maybeNull(types.string),
    organizationIsVerified: types.maybeNull(types.boolean),
    organizationKpp: types.maybeNull(types.string),
    organizationName: types.maybeNull(types.string),
    organizationTelephone: types.maybeNull(types.string),
    organizationTermsIsConfirmed: types.maybeNull(types.boolean),
    partnerCode: types.maybeNull(types.string),
    partnerUuid: types.maybeNull(types.string),
    termsUrl: types.maybeNull(types.string),
    tutorialRead: types.maybeNull(types.boolean),
    canChangePassword: types.maybeNull(types.boolean),
});

export default User;
