import {Bem} from 'atol-atoms/tools';
import {AUTH_ROUTE} from 'constants/routes';
import {Route, Switch} from 'react-router-dom';
import LoginForm from './LoginForm';
import './styles.scss';

const b_ = Bem('AuthWrapper');

function AuthContainer() {
    return (
        <div className={b_()}>
            <div className={b_('rightColumnContentWrapper')}>
                <Switch>
                    <Route exact path={AUTH_ROUTE}>
                        <LoginForm />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}

export default AuthContainer;
