import {types} from 'mobx-state-tree';

export const DeviceItem = types
    .model('DeviceItem', {
        id: types.number,
        syncedOn: types.maybeNull(types.string),
        osVersion: types.maybeNull(types.string),
        osType: types.maybeNull(types.string),
        comment: types.maybeNull(types.string),
        productName: types.maybeNull(types.string),
        typeAlias: types.maybeNull(types.string),
        organizationName: types.maybeNull(types.string),
        deviceModelName: types.maybeNull(types.string),
        status: types.maybeNull(types.string),
        serialNumber: types.maybeNull(types.string),
    });

export default DeviceItem;
