import {environment, CustomWindowConfigWithStore} from 'configs';
import AppContainer from 'containers/AppContainer';
import {configure} from 'mobx';
import makeInspectable from 'mobx-devtools-mst';
import ReactDOM from 'react-dom/client';
import {Router} from 'react-router-dom';
import Store from 'stores';
import {setStore} from 'tools/connect';
import history from 'tools/history';
import packageJson from '../package.json';

configure({useProxies: 'never'});
// eslint-disable-next-line no-console
console.log(
    '%s %c %s',
    packageJson.description,
    'color:#358ed7',
    packageJson.version,
);

const store = Store.create({});

if (environment !== 'production') {
    (window as CustomWindowConfigWithStore).store = store;
    makeInspectable(store);
}
setStore(store);

const APP_ROOT_ID = 'application_root';
const CREATE_ROOT = document.getElementById(APP_ROOT_ID) as Element;
const root = ReactDOM.createRoot(CREATE_ROOT);
root.render(
    <Router history={history}>
        <AppContainer />
    </Router>,
);

// if (environment !== 'production' && (module as any).hot) {
//     (module as any).hot.accept('./containers/AppContainer', () => {
//         // eslint-disable-next-line
//         const NextApp = require('./containers/AppContainer').default;
//         renderApp(NextApp);
//     });
// }
