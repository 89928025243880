/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// @ts-nocheck
import {types, flow, getRoot} from 'mobx-state-tree';
import api from 'api';
import utils from 'tools/img';
import CommentModal from './commentModal';

export const Base = types
    .model('Base', {
        id: types.maybeNull(types.number),
        percentCharging: types.maybeNull(types.union(types.number, types.string)),
        isCharging: types.maybeNull(types.boolean),
        freeSpaceStorageInPercent: types.maybeNull(types.union(types.number, types.string)),
        continuousOperationTime: types.maybeNull(types.string),
        systemDatetime: types.maybeNull(types.string),
        syncedOn: types.maybeNull(types.string),
        osPlatform: types.maybeNull(types.string),
        imei: types.maybeNull(types.string),
        systemUuid: types.maybeNull(types.string),
        serialNumber: types.maybeNull(types.string),
        osVersion: types.maybeNull(types.string),
        uin: types.maybeNull(types.string),
        osBuildNumber: types.maybeNull(types.string),
        productName: types.maybeNull(types.string),
        typeAlias: types.maybeNull(types.string),
        comment: types.maybeNull(types.string),
        organizationName: types.maybeNull(types.string),
        status: types.maybeNull(types.string),
        productImageUrl: types.maybeNull(types.string),
        img: types.maybeNull(types.string),
        type: types.maybeNull(types.string),
    });

const initialStore = {
    id: null,
    percentCharging: null,
    isCharging: null,
    freeSpaceStorageInPercent: null,
    continuousOperationTime: null,
    systemDatetime: null,
    syncedOn: null,
    osPlatform: null,
    imei: null,
    systemUuid: null,
    serialNumber: null,
    osVersion: null,
    uin: null,
    osBuildNumber: null,
    productName: null,
    typeAlias: null,
    comment: null,
    organizationName: null,
    status: null,
    productImageUrl: null,
    img: null,
};

const BaseStore = types.model('BaseStore', {
    commentModal: types.optional(CommentModal, {}),
    data: types.optional(Base, {}),
    isLoading: types.optional(types.boolean, false),
    error: types.optional(types.frozen(), null),
}).actions((self) => {
    const model = self;
    const setError = getRoot(self).apiError.set as (code: number, message: string)=> void;
    const refreshToken = getRoot(self).auth.refreshToken as ()=> void;

    const getImg = flow(function* ajax(relativePath: string) {
        try {
            const img = yield api.get({path: `/static/?relative_path=${relativePath}`, responseFormat: 'json', setError, refreshToken});

            model.data.img = utils.convertStringToBase64(img as string);
        } catch (err) {
            console.error(err);
        }
    });

    const get = flow(function* ajax(serialNumber: string) {
        model.isLoading = true;
        model.error = null;
        try {
            const data = yield api.get({path: `/device_basic_info/?serial_number=${serialNumber}`, responseFormat: 'json', setError, refreshToken});
            data.comment = data.comment || '';
            model.data = data;
            yield getImg(data.productImageUrl as string);
        } catch (err) {
            const error = JSON.parse(err?.message);
            model.data = initialStore;
            model.error = error;
            model.isLoading = false;
        }
        model.isLoading = false;
    });

    const clear = () => {
        model.data = initialStore;
        model.error = null;
    };

    return {get, clear};
});

export default BaseStore;
