/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {types} from 'mobx-state-tree';
import WizardItem from './wizardItem';
import operations from './operations';
import {IWizard} from '.';

const Wizard = types
    .model('Wizard', {
        wizardList: types.array(WizardItem),
        initialSerialIds: types.optional(types.array(types.string), []),
        isLoading: types.optional(types.boolean, true),
    })
    .views((wizard) => ({
        get current() {
            return wizard.wizardList.length > 0
                ? wizard.wizardList[wizard.wizardList.length - 1]
                : null;
        },
        get prevOperation() {
            return wizard.wizardList.length > 1
                ? wizard.wizardList[wizard.wizardList.length - 2]
                : null;
        },
    }))
    .volatile(() => ({
        // callback: types.function,
    }))
    .actions((self) => {
        const wizard = self as IWizard;

        // eslint-disable-next-line default-param-last
        const addOperation = (
            operation,
            operationDefaults = {},
            serialId = [],
        ) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const serialNumbers = (serialId as any).toJSON
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                ? (serialId as any).toJSON()
                : serialId;
            wizard.wizardList.push(
                WizardItem.create({
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    serialNumbers: serialNumbers.length
                        ? serialNumbers
                        : wizard.initialSerialIds.toJSON(),
                    ...(operation && {
                        operation:
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                            operations[operation].create(operationDefaults),
                    }),
                }),
            );
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        };

        const setOperation = (operation, operationDefaults = {}) => {
            if (wizard.current) {
                wizard.current.setOperation(operation, operationDefaults);
            } else {
                wizard.addOperation(operation, operationDefaults, []);
            }
        };

        const completeOperation = () => {
            // wizard.wizardList = wizard.wizardList.slice(0, -1);
            wizard.proceed();
        };

        const proceed = () => {
            if (wizard.current
                && typeof wizard.current.operation.proceed === 'function') {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                wizard.current.operation.proceed();
            } else {
                wizard.discardOperations();
            }
        };

        const discardOperations = () => {
            // Not_today goal also tracked in Error model of operation
            // if (wizard.current && !wizard.current.operation.error) wizard.sendMetric('Discard');
            wizard.wizardList = null;
            // if (typeof wizard.callback === 'function') {
            // wizard.callback();
            // }
            // wizard.callback = [null];
        };

        const setCallback = (callback) => {
            // wizard.callback = callback;
        };

        const discardAndAddOperation = (operation) => {
            // if (wizard.current && !wizard.current.operation.error) wizard.sendMetric('Discard');
            addOperation(operation, {}, []);
        };

        const onPopState = () => {
            wizard.wizardList = null;
        };

        return {
            addOperation,
            setCallback,
            completeOperation,
            discardOperations,
            setOperation,
            discardAndAddOperation,
            proceed,
            onPopState,
        };
    });

export default Wizard;
