import {IconStatusColorType} from 'atol-atoms/IconStatus';
import {TErrorType} from '.';

const errorConfig: Record<TErrorType, {
    iconStatus: IconStatusColorType,
    title: string,
    description: string,
    errorCode: string,
    buttonLabel?: string,
    onClickButton?: ()=> void,
}> = {
    400: {
        iconStatus: 'negative',
        title: 'Запрашиваемая страница не работает',
        description: 'Если ошибка повторится, напишите нам. Мы постараемся решить проблему в кратчайшие сроки.',
        errorCode: 'Ошибка 400',
        buttonLabel: 'Перезагрузить страницу',
        onClickButton: () => window.location.reload(),
    },
    401: {
        iconStatus: 'negative',
        title: 'Истекло время действия токена',
        description: 'Перезагрузите страницу',
        errorCode: 'Ошибка 401',
        buttonLabel: 'Перезагрузить страницу',
        onClickButton: () => window.location.reload(),
    },
    402: {
        iconStatus: 'negative',
        title: 'Ошибка авторизации',
        description: 'Повторите попытку авторизации в системе',
        errorCode: 'Ошибка 402',
        buttonLabel: 'Авторизация',
    },
    403: {
        iconStatus: 'warning',
        title: 'Доступ запрещен',
        description: 'Для дальнейших действий обратитесь к администратору',
        errorCode: 'Ошибка 403',
    },
    404: {
        iconStatus: 'negative',
        title: 'Запрашиваемая страница не работает',
        description: 'Если ошибка повторится, напишите нам. Мы постараемся решить проблему в кратчайшие сроки.',
        errorCode: 'Ошибка 404',
        buttonLabel: 'Перезагрузить страницу',
        onClickButton: () => window.location.reload(),
    },
    500: {
        iconStatus: 'negative',
        title: 'Внутренняя ошибка сервера',
        description: 'Извините, что-то пошло не так',
        errorCode: 'Ошибка 500',
        buttonLabel: 'Перезагрузить страницу',
        onClickButton: () => window.location.reload(),
    },
    503: {
        iconStatus: 'warning',
        title: 'Сервис не доступен',
        description: 'Перезагрузите страницу или повторите попытку позже',
        errorCode: 'Ошибка 503',
        buttonLabel: 'Перезагрузить страницу',
        onClickButton: () => window.location.reload(),
    },
};

export default errorConfig;
