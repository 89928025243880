/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
// @ts-nocheck
import {types} from 'mobx-state-tree';
import {formatDateForApi} from 'atol-atoms/tools/dateFormat';

const DateFilter = types
    .model('DateFilter', {
        id: types.optional(types.string, ''),
        dates: types.array(types.frozen(null)),
        selectedDates: types.array(types.frozen(null)),
        name: types.optional(types.string, ''),
        availableDates: types.maybeNull(types.array(types.frozen(null))),
        type: types.optional(types.string, 'date'),
        isActive: types.optional(types.boolean, false),
        dateOnly: types.optional(types.boolean, false),
    })
    .actions((self) => {
        const dateFilter = self;

        const onChange = (value: any[]) => {
            dateFilter.selectedDates = value;
        };

        const fromQuery = (value: any[]) => {
            dateFilter.selectedDates = value;
            dateFilter.dates = value;
            dateFilter.isActive = true;
        };

        const resetSelected = () => {
            if (!dateFilter.isActive) {
                dateFilter.selectedDates = [];
            }
        };

        const setFilters = () => {
            dateFilter.isActive = true;
            dateFilter.dates = dateFilter.selectedDates?.toJSON();
        };

        const set = (val: string[] = []) => {
            const value = [];
            if (!val[0]) return;
            value[0] = new Date(val[0]);
            if (val[1]) value[1] = new Date(val[1]);
            dateFilter.fromQuery(value);
        };

        const reset = () => {
            dateFilter.dates = [];
            dateFilter.selectedDates = [];
            dateFilter.isActive = false;
        };

        return {onChange, reset, resetSelected, set, setFilters, fromQuery};
    })
    .views((dateFilter) => ({
        get isAllSelected() {
            return dateFilter.selectedDates.length === 2;
        },
        get isAnyActive() {
            return dateFilter.dates.length > 0;
        },
        get isAnySelected() {
            return dateFilter.selectedDates.length > 0;
        },
        get queryString() {
            if (dateFilter.dates.length === 0) return '';
            const queryStringArray = [
                `${dateFilter.prefix}from_dt=${formatDateForApi(dateFilter.dates[0], dateFilter.dateOnly)}`,
            ];
            if (dateFilter.dates.length === 2) {
                queryStringArray.push(
                    `${dateFilter.prefix}to_dt=${formatDateForApi(dateFilter.dates[1], dateFilter.dateOnly)}`,
                );
            }
            return dateFilter.isActive ? queryStringArray.join('&') : '';
        },
        get prefix() {
            return dateFilter.name ? dateFilter.name + '_' : '';
        },
    }));
export default DateFilter;
