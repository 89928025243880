import Button from 'atol-atoms/Button2';
import Spacer from 'atol-atoms/Spacer';
import {Fragment} from 'react';

import {Bem} from 'atol-atoms/tools';
import {AuthProps} from 'stores/auth';
import connect from 'tools/connect';
import './style.scss';

const b_ = Bem('LoginForm');

function AtolIdIcon() {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.7 0H13.3C15.0673 0 16.5 1.43269 16.5 3.2V12.8C16.5 14.5673 15.0673 16 13.3 16H3.7C1.93269 16 0.5 14.5673 0.5 12.8V3.2C0.5 1.43269 1.93269 0 3.7 0ZM5.7752 11.6H4.2752V4.6H5.7752V11.6ZM9.6252 11.6H7.0752V4.6H9.6252C11.8748 4.6 13.4252 6.15 13.4252 8.1C13.4252 10.05 11.8752 11.6 9.6252 11.6ZM8.5752 6V10.2H9.6252C11.0252 10.2 11.8752 9.35 11.8752 8.1C11.8752 6.85 11.0252 6 9.6252 6H8.5752Z" fill="#DF192C" />
        </svg>

    );
}

function LoginForm({auth}: AuthProps) {
    return (
        <Fragment>
            <Spacer size={16} />
            <div className={b_('buttonsWrapper')}>
                <Button
                    onClick={auth.atolIdLogin}
                    className="wide"
                    type="secondary"
                >
                    <Fragment>
                        <AtolIdIcon />
                        <Spacer size={10} vertical />
                        Войти с помощью АТОЛ ID
                    </Fragment>
                </Button>
                <Spacer size={32} />
            </div>
        </Fragment>
    );
}

export default connect(({auth}) => ({auth}))(LoginForm);
