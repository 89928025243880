/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {types, flow, getRoot, applySnapshot} from 'mobx-state-tree';
import api from 'api';
import {IConfigurationsStatus} from 'types/device';
import {IList} from 'types';

export const Configurations = types
    .model('Configurations', {
        serialNumber: types.maybeNull(types.string),
        responseStatus: types.string,
        data: types.model({
            id: types.string,
            deviceStatus: types.string,
            bankInfo: types.array(types.model({
                bankId: types.maybeNull(types.number),
                bankName: types.maybeNull(types.string),
                tid: types.maybeNull(types.string),
                merchant: types.maybeNull(types.string),
                submerchantId: types.maybeNull(types.number),
            })),
        }),
    });

const ConfigurationsStore = types.model('ConfigurationsStore', {
    data: types.maybeNull(Configurations),
    configuration: types.optional(types.string, ''),
    isLoading: types.optional(types.boolean, false),
    bankError: types.optional(types.frozen(), null),
    configurationError: types.optional(types.frozen(), null),
}).actions((self) => {
    const model = self;
    const refreshToken = getRoot(self).auth.refreshToken as ()=> void;

    const get = flow(function* ajax(serialNumber: string) {
        model.isLoading = true;
        try {
            const data = yield api.get<IList<IConfigurationsStatus>>({path: `/device/pay/status/?serial_number=${serialNumber}`, refreshToken});

            model.data = data;
        } catch (err) {
            try {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                const error = JSON.parse(err.message);
                model.bankError = error;
            } catch (e) {
                model.bankError = {code: '', message: 'На сервере произошла ошибка, повторите попытку позже'};
            }
        }
        model.isLoading = false;
    });

    const getConfig = flow(function* ajax(serialNumber: string) {
        model.isLoading = true;
        try {
            const data = yield api.get<{}>({path: `/device/pay/configuration/?serial_number=${serialNumber}`, refreshToken});

            model.configuration = data;
        } catch (err) {
            try {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                const error = JSON.parse(err.message);
                model.configurationError = error;
            } catch (e) {
                model.configurationError = {code: '', message: 'На сервере произошла ошибка, повторите попытку позже'};
            }
        }
        model.isLoading = false;
    });

    const init = flow(function* ajax(serialNumber: string) {
        model.isLoading = true;
        yield Promise.all([
            get(serialNumber),
            getConfig(serialNumber),
        ]);
    });

    const clear = () => {
        applySnapshot(model, {});
    };

    return {init, clear};
});

export default ConfigurationsStore;
