/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {Instance, types, SnapshotIn, SnapshotOut, IStateTreeNode} from 'mobx-state-tree';

import difference from 'lodash/difference';

import operations from './operations';

const WizardItem = types
    .model('WizardItem', {
        serialNumbers: types.array(types.string),
        operation: types.maybeNull(
            types.union(...Object.values(operations)),
        ),
    })
    .actions((self) => {
        const wizardItem: IStateTreeNode<typeof WizardItem> = self;
        const setSerialNumbers = (serialNumbers) => ((wizardItem as any).serialNumbers = [...serialNumbers]);

        const addSerialNumber = (serialNumber: string) => wizardItem.serialNumbers.push(serialNumber);

        const removeSerialNumbers = (...serialNumbers) => {
            return (wizardItem.serialNumbers = difference(
                self.serialNumbers,
                serialNumbers,
            ));
        };

        const setOperation = (operation, operationDefaults = {}) => (self.operation = operations[operation].create(operationDefaults));

        return {
            setSerialNumbers,
            addSerialNumber,
            removeSerialNumbers,
            setOperation,
        };
    });

export default WizardItem;

export type IWizardItem = Instance<typeof WizardItem>
export type IWizardItemSnapshotIn = SnapshotIn<typeof WizardItem>
export type IWizardItemSnapshotOut = SnapshotOut<typeof WizardItem>
