import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import connect from 'tools/connect';
import Popup from 'atol-atoms/Popup';
// import SelectSerialNumbers from './SelectSerialNumbers';
import Operation from './Operation';
import './style.scss';

interface WizardProps {
  wizard: {
    onPopState: ()=> void;
    showOnPage: boolean;
    // TODO: types
    wizardList: any[];
    current: {
      operation: {
        name: string;
        isSerialRequired: boolean;
      };
      serialNumbers: string[];
    };
  };
}

function Wizard({wizard}: WizardProps) {
    const history = useHistory();
    useEffect(() => {
        return history.listen(() => {
            wizard.onPopState();
        });
    }, []);

    const wizardContent = (
        <div className="Wizard__content">
            {/* {
                current
                    && current.operation
                    && current.serialNumbers.length === 0
                && current.operation.isSerialRequired && <SelectSerialNumbers />
            } */}
            {wizard.current && wizard.current.operation && (wizard.current.serialNumbers.length > 0
            || wizard.current.operation.isSerialRequired === false) && (
                <div bddmark={`операция ${wizard.current.operation.name}`}>
                    <Operation />
                </div>
            )}
        </div>
    );
    if (wizard.showOnPage && wizard.wizardList.length > 0) {
        return (
            <div className="Wizard Wizard_onPage" bddmark="визард">
                {wizardContent}
            </div>
        );
    }
    return (
        <Popup isOpen={wizard.wizardList.length > 0} size="medium" className="Wizard" bddmark="визард">
            {wizardContent}
        </Popup>
    );
}

export default connect(({wizard}) => ({
    wizard,
}))(Wizard);
