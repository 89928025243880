/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// @ts-nocheck
import api from 'api/index';
import {flow, getRoot, types} from 'mobx-state-tree';

const BlobModel = types.custom({
    name: 'BlobModel',
    fromSnapshot(value) {
        return value;
    },
    toSnapshot(value) {
        return value;
    },
    isTargetType(value) {
        return value instanceof Blob;
    },
    getValidationMessage(value) {
        if (!(value instanceof Blob)) {
            return 'Invalid Blob type';
        }
        return '';
    },
});

const removeOffsetAndLimit = (queryString: string): string => {
    const regex = /(^|&)offset=[^&]*&limit=[^&]*&?/;

    let newQueryString = queryString.replace(regex, '$1');

    newQueryString = newQueryString.replace(/^&/, '');

    if (newQueryString) {
        newQueryString = '?' + newQueryString;
    }

    return newQueryString;
};

export const ListFile = types
    .model('ListFile', {
        blob: types.maybeNull(BlobModel),
        fileName: types.maybeNull(types.string),
        error: types.maybeNull(types.string),
        isLoading: types.maybeNull(types.boolean),
    })
    .actions((self) => {
        const model = self;
        const setError = getRoot(self).apiError.set as (code: number, message: string)=> void;

        const getFileLink = flow(function* ajax() {
            model.isLoading = true;
            model.blob = null;
            model.fileName = null;
            model.error = null;

            try {
                const {queryString} = getRoot(self).pages.devices.devicesList.list;
                const data: {blob: any, fileName: string} = yield api.get({path: `/devices/export${removeOffsetAndLimit(queryString)}`, responseFormat: 'blob', setError});

                model.blob = data.blob;
                model.fileName = data.fileName;
                model.isLoading = false;
            } catch (err: any) {
                model.error = err.message;
                model.isLoading = false;
                throw new Error(err.message);
            }
        });

        return {getFileLink};
    });

export default ListFile;
