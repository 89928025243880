// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {IStateTreeNode, getRoot, getType, types} from 'mobx-state-tree';

import {environment} from 'configs';
import {IStore} from 'stores/index';

const Error = types.model('Error', {
    type: types.maybeNull(types.string),
    description: types.array(types.string),
});

const Operation = types.model('', {
    error: types.maybeNull(Error),
    isLoading: types.optional(types.boolean, false),
    isSerialRequired: types.optional(types.boolean, true),
})
    .views((operation) => ({
        get serialIds(): string[] {
            return ['asd'];
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            // return getParent(operation).serialNumbers;
        },
        get name(): string {
            return getType(operation).name;
        },
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        get wizard() {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return getRoot<IStore>(operation).wizard;
        },
    }))
    .actions((self) => {
        const operation: IStateTreeNode<typeof Operation> = self;

        // const throwError = (err: {body: {errorText: Record<string, string>}}) => {
        //     /* eslint-disable no-console */
        //     if (environment === 'development') console.log(err);
        //     /* eslint-enable no-console */
          
        //     let errorObject = err;
        //     if (err.body && err.body.errorText) {
        //         errorObject = err.body.errorText;
        //     } else if (Object.keys(errorObject).length === 0) {
        //         errorObject = 'Попробуйте повторить запрос через некоторое время';
        //     }
          
        //     operation.error = typeof errorObject === 'string'
        //         ? {
        //             type: 'common',
        //             description: [errorObject],
        //         }
        //         : {
        //             type: 'array',
        //             description: Object.keys(errorObject).map(
        //                 (key) => `${key}: ${errorObject[key]}`,
        //             ),
        //         };
        // };

        // const back = () => {
        //     // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        //     const {step, action} = (operation.backStep
        //         // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        //         && operation.backStep[operation.step])
        //         // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        //         || operation.backStep?.default || {step: 'INIT'};

        //     if (action && typeof action === 'function') action();
        //     // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        //     if (step) operation.step = step;
        // };

        return {
            // back,
            // throwError,
        };
    });

export default Operation;
