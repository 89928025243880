import Icon from 'atol-atoms/Icon';
import Menu from 'atol-atoms/Menu';
import {useMedia} from 'atol-atoms/tools';
import {DEVICES_VIEW} from 'constants/routes';
import {Fragment, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import connect from 'tools/connect';
import {MenuProps} from '../types';
import LeftPanelItem from './LeftPanelItem';
import MobileHeader from './MobileHeader';
import footerMenuData from './footerMenuData';
import menuData from './menuData';
import './style.scss';

export const mobileBreakpoint = '768px';

export interface LeftMenuProps {
    auth: {
        atolIdUser: {name: string, email: string},
        atolIdLogout: ()=> void
    }
}

function LeftPanel({atolIdUser, setIsMenuVisible, isMenuVisible, atolIdLogout}: MenuProps) {
    const isCurrentlyMobile = useRef<boolean | null>(null);
    const breakPoints = [
        `(max-width: ${mobileBreakpoint})`,
        `(min-width: ${mobileBreakpoint})`,
    ];
    const isMobile = useMedia(breakPoints, [true, false], false);
    useEffect(() => {
        if (isCurrentlyMobile.current === isMobile) return;
        isCurrentlyMobile.current = isMobile;
        setIsMenuVisible(!isMobile);
    });

    const onMouseEnter = () => {
        let event: Event;
        if (typeof (Event) === 'function') {
            event = new Event('mousedown');
        } else { // trick for IE11 https://stackoverflow.com/questions/27176983/dispatchevent-in-chrome-but-not-in-ie11
            event = document.createEvent('Event');
            event.initEvent('mousedown', true, true);
        }
        // trick to close open dropdown
        document.dispatchEvent(event);
    };

    return (
        <Fragment>
            {isMenuVisible && <div className="MenuOverlay" />}
            <div onMouseEnter={onMouseEnter}>
                <Menu
                    logoTitle="TMS"
                    breakPoints={breakPoints}
                    isVisible={isMenuVisible}
                    mobileHeader={
                        <MobileHeader atolIdUser={atolIdUser} atolIdLogout={atolIdLogout} />
                    }
                    logo={(
                        <Link to={DEVICES_VIEW}>
                            <Icon icon="atol-a" size={32} status="white" />
                            {/* <img src={`${process.env.PUBLIC_URL}/images/newyearlogo.svg`} alt="logo" /> */}
                        </Link>
                    )}
                    className="LeftPanel"
                    footer={(
                        <LeftPanelItem
                            menuData={footerMenuData}
                            isMobile={isMobile}
                            setIsMenuVisible={setIsMenuVisible}
                        />
                    )}
                    type="light"
                >
                    <LeftPanelItem
                        menuData={menuData}
                        isMobile={isMobile}
                        setIsMenuVisible={setIsMenuVisible}
                    />
                </Menu>
            </div>
        </Fragment>
    );
}

export default connect(({auth: {atolIdUser, atolIdLogout}}) => ({
    atolIdUser,
    atolIdLogout,
}))(LeftPanel);
