import {environment} from 'configs';
import CabinetContainer from 'containers/CabinetContainer';
import {Suspense, lazy, useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import connect from 'tools/connect';

import Loader from 'atol-atoms/Loader';
import NotificationManager from 'components/NotificationManager';
import AuthContainer from 'containers/AuthContainer';
import {AuthProps} from 'stores/auth';
import 'style/global-style.scss';
import './style.scss';

const DevSettings = lazy(() => import('components/DevSettings'));

export function AppContainer({auth}: AuthProps) {
    useEffect(() => {
        auth.init();
    }, []);

    if (auth.isLoading) {
        return <Loader />;
    }

    if (!auth.isIdAuth) {
        return <AuthContainer />;
    }

    return (
        <div className="appContainer">
            <Switch>
                <Route path="/:main_section?/:sub_section?">
                    <CabinetContainer />
                </Route>
            </Switch>
            <Suspense fallback={<Loader />}>
                <NotificationManager />
            </Suspense>
            {environment !== 'production' && (
                <Suspense fallback={<Loader />}>
                    <DevSettings />
                </Suspense>
            )}
        </div>
    );
}

export default connect(({auth}) => ({auth}))(AppContainer);
