import {IconType} from 'atol-atoms/Icon';
import {environment} from 'configs';
import {ATOL_TERMS_LINK} from 'constants/links';
import {
    PROFILE_ROUTE,
    ROUTER_SCHEME,
    RouteConfig,
} from 'constants/routes';
import {getStore} from 'tools/connect';

const footerMenuData: RouteConfig[] = [
    {
        icon: 'profile',
        title: 'Профиль',
        links: [
            ROUTER_SCHEME[PROFILE_ROUTE],
        ],
    },
    {
        icon: 'question-invert',
        title: 'Помощь',
        links: [
            ...(environment !== 'production'
                ? [
                    {
                        title: 'Настройки',
                        icon: 'set' as IconType,
                        access: {scope: ['partner', 'client']},
                        onClick: () => getStore().common.devSettings.openSettings(),
                    },
                ]
                : []),
            {
                title: 'Условия использования',
                icon: 'document',
                href: ATOL_TERMS_LINK,
            },
        ],
    },
];

export default footerMenuData;
