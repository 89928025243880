/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
/* eslint-disable no-param-reassign */
import {IStateTreeNode, Instance, SnapshotIn, SnapshotOut, types} from 'mobx-state-tree';
import Notification from './notification';
import DevSettings from './devSettings';
import TopPanel from './topPanel';

type RemoveNotification = (id: number)=> any

const Common = types
    .model('Common', {
        notifications: types.array(Notification),
        devSettings: types.optional(DevSettings, {}),
        topPanel: types.optional(TopPanel, {}),
    })
    // .volatile(() => ({
    //     historyUnblock: types.function,
    // }))
    .actions((self) => {
        const common: IStateTreeNode<typeof Common> = self;
        const addNotification = (notification: typeof Notification) => {
            const notificationText = notification instanceof Error
                ? notification.message
                : notification;
            common.notifications.push({
                content:
                    notificationText === 'Failed to fetch'
                        ? 'Произошла неизвестная ошибка'
                        : notificationText,
            });
        };

        const updateNotification = (id: number) => {
            removeNotifiocation(id);
        };

        const removeNotifiocation = (id): RemoveNotification => (common.notifications = common.notifications.filter(
            (notification) => notification.id !== id,
        ));

        return {
            addNotification,
            updateNotification,
            removeNotifiocation,
        };
    });

export default Common;
export type ICommon = Instance<typeof Common>
export type ICommonSnapshotIn = SnapshotIn<typeof Common>
export type ICommonSnapshotOut = SnapshotOut<typeof Common>
