/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-implied-eval */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// @ts-nocheck
import {flow, types, getParent, addMiddleware, createActionTrackingMiddleware2} from 'mobx-state-tree';
import {reaction} from 'mobx';
import Filter from './filter';
import DateFilter from './dateFilter';
import GroupFilter from './groupFilter';
import SortItem from './sortItem';
import QuickSearch from './quickSearch';

const List = (ItemsModel) => {
    const Model = types
        .model('List', {
            items: types.array(ItemsModel),
            count: types.optional(types.number, 0),
            offset: types.optional(types.number, 0),
            limit: types.optional(types.number, 10),
            isLoading: types.optional(types.boolean, false),
            initialLoadComplete: types.optional(types.boolean, false),
            filters: types.array(
                types.union({
                    dispatcher(snapshot) {
                        if (snapshot.type === 'date') {
                            return DateFilter;
                        }
                        if (snapshot.type === 'group') {
                            return GroupFilter;
                        }
                        return Filter;
                    },
                    Filter,
                    DateFilter,
                    GroupFilter,
                }),
            ),
            sorts: types.array(SortItem),
            searchField: types.maybeNull(types.string),
            queryTimeout: types.optional(types.number, 300),
            errorText: types.maybeNull(types.string),
            getListFunctionName: types.optional(types.string, 'getList'),
            quickSearch: types.optional(QuickSearch, {}),
        })
        .volatile(() => ({
            queryTimeoutId: null,
            getListActionId: null,
            reactionDisposers: [],
            historyDisposer: () => {},
        }))
        .actions((self) => {
            const model = self;

            const _getList = getParent(model)[model.getListFunctionName];

            const getList = flow(function* ajax() { // use model.list.getList() instead of model.getList() in callbacks
                yield _getList();
                if (!model.errorText && model.items.length === 0 && model.count > 0 && model.offset > 0) {
                    const maxOffset = Math.floor(model.count / model.limit) * model.limit;
                    const validOffset = maxOffset < model.count ? maxOffset : maxOffset - model.limit;
                    model.setOffset(validOffset);
                }
            });

            const abortGetListMiddleware = (call, next, abort) => {
                if (
                    call.parentActionEvent
                    && call.parentActionEvent.id !== model.getListActionId
                    && call.parentActionEvent.name === model.getListFunctionName
                ) {
                    abort(call);
                } else {
                    next(call);
                }
            };
            const getListTrackingMiddleware = createActionTrackingMiddleware2({
                filter: (call) => call.name === model.getListFunctionName,
                onStart: (call) => model.onStart(call),
                onFinish: () => model.onFinish(),
            });

            const onStart = (call) => {
                model.getListActionId = call.id;
                model.errorText = null;
            };

            const onFinish = () => {
                model.queryTimeoutId = null;
                if (model.initialLoadComplete === false) {
                    model.initialLoadComplete = true;
                }
            };

            addMiddleware(model.items, abortGetListMiddleware);
            addMiddleware(getParent(model), getListTrackingMiddleware);

            const attach = () => {
                model.getStateFromSearch();
                const sheduler = () => {
                    setTimeout(() => {
                        model.getStateFromSearch();
                        if (model.historyDisposer) model.scheduleRefresh(0);
                    }, 0);
                };
                model.historyDisposer = (() => {
                    const savedPushState = window.history.pushState;
                    window.history.pushState = (...args) => {
                        sheduler();
                        return savedPushState.apply(window.history, args);
                    };
                    window.addEventListener('popstate', sheduler);
                    return () => {
                        window.removeEventListener('popstate', sheduler);
                        window.history.pushState = savedPushState;
                    };
                })();

                model.reactionDisposers = [
                    reaction(
                        () => [
                            model.filterQueryString,
                            model.searchQueryString,
                            model.pagingQueryString,
                            model.sortQueryString,
                        ],
                        () => {
                            const url = new URL(window.location);
                            const historySearch = url.searchParams;
                            const listSearch = new URLSearchParams(model.queryString);
                            [
                                'offset',
                                'limit',
                                'sort_by',
                                ...(model.searchField ? [model.searchField] : []),
                                ...model.filters
                                    .map((filter) => {
                                        if (filter.type === 'date') return [`${filter.prefix}from_dt`, `${filter.prefix}to_dt`];
                                        return filter.name;
                                    })
                                    .flat(),
                            ].forEach((key) => historySearch.delete(key));

                            for (const [name, value] of listSearch) {
                                historySearch.append(name, value);
                            }
                            window.history.replaceState({}, '', url);
                        },
                    ),
                    reaction(
                        () => [model.filterQueryString, model.searchQueryString, model.sortQueryString],
                        () => {
                            model.scheduleRefresh();
                            model.setOffset(0);
                        },
                    ),
                    reaction(() => model.pagingQueryString, model.scheduleRefresh),
                ];
                model.scheduleRefresh(0);
            };

            const scheduleRefresh = (timeout = model.queryTimeout) => {
                if (!_getList) return;
                clearTimeout(model.queryTimeoutId);
                model.queryTimeoutId = setTimeout(model.getList, timeout);
            };

            const getStateFromSearch = () => {
                model.resetFilters();
                model.sorts = [];
                const historySearch = new URLSearchParams(window.location.search);
                if (historySearch.has('offset')) model.offset = Number(historySearch.get('offset'), 10);
                if (historySearch.has('limit')) model.limit = Number(historySearch.get('limit'), 10);
                if (historySearch.has(model.searchField)) {
                    model.quickSearch.changeSearchString(historySearch.get(model.searchField));
                }
                model.sorts = historySearch
                    .getAll('sort_by')
                    .map((item) => ({name: item.slice(1), direction: item.slice(0, 1)}));
                model.filters.forEach((filter) => {
                    if (filter.type === 'date') {
                        filter.set([
                            historySearch.get(filter.prefix + 'from_dt'),
                            historySearch.get(filter.prefix + 'to_dt'),
                        ]);
                    } else {
                        if (!historySearch.has(filter.name)) return;
                        historySearch.getAll(filter.name).forEach((item) => {
                            let val = item;
                            if (filter.type === 'boolean' && (item === 'true' || item === 'false')) {
                                val = val === 'true';
                            }
                            filter.toggleByValue(val);
                        });
                    }
                });
            };

            const resetFilters = () => {
                model.filters.forEach((filter) => filter.reset());
            };
            const setOffset = (offset) => {
                model.offset = offset;
            };

            const setPageSize = (pageSize) => {
                model.limit = pageSize;
            };

            const detach = () => {
                model.historyDisposer();
                model.historyDisposer = null;
                model.reactionDisposers.forEach((dispose) => dispose());
                model.offset = 0;
                model.resetFilters();
                model.quickSearch.reset();
                model.initialLoadComplete = false;
            };

            const toggleSort = (name) => {
                const target = model.sort[name];
                if (target && target.direction === '+') {
                    model.sorts = model.sorts.filter((item) => item.name !== name);
                } else if (target && target.direction === '-') {
                    target.direction = '+';
                } else {
                    model.sorts.push({name, value: '-'});
                }
            };

            const toggleSingleSort = (name) => {
                const target = model.sort[name];
                if (target && target.direction === '+') {
                    model.sorts = model.sorts.filter((item) => item.name !== name);
                } else if (target && target.direction === '-') {
                    target.direction = '+';
                } else {
                    model.sorts = [{name, value: '-'}];
                }
            };

            const setError = (error) => {
                let errorText = error instanceof Error ? error.message : error;
                if (!errorText || errorText === 'Failed to fetch') {
                    errorText = 'Произошла ошибка и мы уже решаем эту проблему';
                }
                model.errorText = errorText;
            };

            const resetListError = () => {
                model.scheduleRefresh(0);
                model.quickSearch.changeSearchString('');
                model.offset = 0;
                model.limit = 10;
                model.resetFilters();
            };

            return {
                attach,
                setOffset,
                setPageSize,
                getStateFromSearch,
                scheduleRefresh,
                detach,
                resetFilters,
                toggleSort,
                toggleSingleSort,
                setError,
                resetListError,
                onFinish,
                onStart,
                getList,
            };
        })
        .views((model) => ({
            get filter() {
                const filters = {};
                model.filters.forEach((item) => {
                    filters[item.id] = item;
                });
                return filters;
            },
            get isFilterActive() {
                if (!Object.keys(model.filter).length) return false;
                return Object.values(model.filter).some((filter) => filter.isAnyActive || filter.isAnyGroupActive);
            },
            get isFilterSelected() {
                if (!Object.keys(model.filter).length) return false;
                return Object.values(model.filter).some((filter) => filter.isAnySelected);
            },
            get sort() {
                const sorts = {};
                model.sorts.forEach((item) => {
                    sorts[item.name] = item;
                });
                return sorts;
            },
            get filterQueryString() {
                return model.filters
                    .map((item) => item.queryString)
                    .filter(Boolean)
                    .join('&');
            },
            get pagingQueryString() {
                return `offset=${model.offset}&limit=${model.limit}`;
            },
            get searchQueryString() {
                if (!model.searchField) return '';
                const {searchString} = model.quickSearch;
                return searchString ? `${model.searchField}=${encodeURIComponent(searchString)}` : '';
            },
            get sortQueryString() {
                return model.sorts.map((item) => `sort_by=${encodeURIComponent(item.direction)}${item.name}`).join('&');
            },
            get reportQueryString() {
                return [
                    model.filterQueryString,
                    model.searchQueryString,
                    model.sortQueryString,
                ]
                    .filter(Boolean)
                    .join('&');
            },
            get queryString() {
                return [
                    model.pagingQueryString,
                    model.filterQueryString,
                    model.searchQueryString,
                    model.sortQueryString,
                ]
                    .filter(Boolean)
                    .join('&');
            },
            get isListEmpty() {
                return !model.queryTimeoutId
                    && !model.isLoading
                    && model.items.length === 0
                    && !model.searchQueryString
                    && !model.isFilterActive
                    && model.offset === 0;
            },
            get isSearchListEmpty() {
                return !model.isLoading
                    && model.items.length === 0
                    && (model.searchQueryString || model.isFilterActive || model.offset > 0);
            },
        }));
    return Model;
};
export default List;
