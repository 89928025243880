/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// @ts-nocheck
import {types, flow, getRoot} from 'mobx-state-tree';
import api from 'api';
import {IDeviceAdditionalInfo} from 'types/device';

export const Additional = types
    .model('Additional', {
        id: types.maybeNull(types.number),
        batteryTemp: types.maybeNull(types.union(types.number, types.string, types.undefined)),
        batteryVoltage: types.maybeNull(types.union(types.number, types.string, types.undefined)),
        cpuTemp: types.maybeNull(types.union(types.number, types.string, types.undefined)),
        cpuLoad: types.maybeNull(types.union(types.number, types.string, types.undefined)),
        scaleDisplay: types.maybeNull(types.union(types.string, types.undefined)),
        scaleFont: types.maybeNull(types.union(types.string, types.undefined)),
        totalRam: types.maybeNull(types.union(types.number, types.string, types.undefined)),
        freePercentRam: types.maybeNull(types.union(types.number, types.string, types.undefined)),
        cpuModel: types.maybeNull(types.union(types.string, types.undefined)),
        isRooted: types.maybeNull(types.union(types.boolean, types.undefined)),
        foldersApp: types.maybeNull(types.union(types.number, types.string, types.undefined)),
        foldersMonitoringagent: types.maybeNull(types.union(types.number, types.string, types.undefined)),
        foldersUpdater: types.maybeNull(types.union(types.number, types.string, types.undefined)),
        foldersOta: types.maybeNull(types.union(types.number, types.string, types.undefined)),
        foldersSystem: types.maybeNull(types.union(types.number, types.string, types.undefined)),
        foldersSdcard: types.maybeNull(types.union(types.number, types.string, types.undefined)),
        securityBoardSerialNumber: types.maybeNull(types.union(types.string, types.undefined)),
        flashMemoryDate: types.maybeNull(types.union(types.string, types.undefined)),
        flashMemoryFwrev: types.maybeNull(types.union(types.string, types.undefined)),
        flashMemoryHwrev: types.maybeNull(types.union(types.string, types.undefined)),
        flashMemoryCid: types.maybeNull(types.union(types.string, types.undefined)),
        flashMemoryManfid: types.maybeNull(types.union(types.string, types.undefined)),
        flashMemoryName: types.maybeNull(types.union(types.string, types.undefined)),
        flashMemoryOemid: types.maybeNull(types.union(types.string, types.undefined)),
        flashMemorySerial: types.maybeNull(types.union(types.string, types.undefined)),
        flashMemoryType: types.maybeNull(types.union(types.string, types.undefined)),
        flashMemorySize: types.maybeNull(types.union(types.number, types.string, types.undefined)),
    });

const AdditionalStore = types.model('AdditionalStore', {
    data: types.optional(Additional, {}),
    isLoading: types.optional(types.boolean, false),
    error: types.optional(types.frozen(), null),
}).actions((self) => {
    const model = self;

    const get = flow(function* ajax(serialNumber: string) {
        model.isLoading = true;
        model.error = null;
        const setError = getRoot(self).apiError.set as (code: number, message: string)=> void;
        const refreshToken = getRoot(self).auth.refreshToken as ()=> void;

        try {
            model.data = yield api.get<IDeviceAdditionalInfo>({path: `/device_additional_info/?serial_number=${serialNumber}`, responseFormat: 'json', setError, refreshToken});
        } catch (err) {
            try {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                const error = JSON.parse(err.message);
                model.error = error;
            } catch (e) {
                model.error = {code: '', message: 'На сервере произошла ошибка, повторите попытку позже'};
            }
        }
        model.isLoading = false;
    });

    return {get};
});

export default AdditionalStore;
