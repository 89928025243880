import {types} from 'mobx-state-tree';

interface NotificationModel {
    id: number,
    content: any,
}

export interface NotificationManagerProps {
    notifications: NotificationModel[],
    updateNotification: ()=> void
}

export interface NotificationsFromCommon {
    common: {
        notifications?: Pick<NotificationManagerProps, 'notifications'>
        updateNotification?: Pick<NotificationManagerProps, 'updateNotification'>
    }
}

const Notification = types.model('Notification', {
    id: types.optional(types.number, () => Date.now() + Math.random()),
    content: types.frozen({}),
});
export default Notification;
