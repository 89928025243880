import LoaderComponent from 'atol-atoms/Loader';

interface Loader {
    title?: string
}

export default function Loader({title = 'Загрузка'}: Loader) {
    return (
        <div className="Wizard__loader">
            <LoaderComponent desc={title} />
        </div>
    );
}
