/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {Instance, flow, types, SnapshotIn, SnapshotOut, getRoot} from 'mobx-state-tree';
import KeycloakService from 'stores/kk';
import history from 'tools/history';
import api from 'api/index';
import User from './user';

export interface AuthProps {
    auth: {
        atolIdUser: {
            name: string | null,
            email: string | null
        },
        init: ()=> void,
        atolIdLogin: ()=> void,
        atolIdLogout: ()=> void,
        checkUser: ()=> void,
        isLoading: boolean,
        isIdAuth: boolean,
        mainPageUrl: string,
    }
}

const AtolIdUser = types.model('AtolIdUser', {
    name: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
});

const Permission = types.model('Permission', {
    rsid: types.maybeNull(types.string),
    rsname: types.maybeNull(types.string),
    scopes: types.maybeNull(types.array(types.string)),
});

const Auth = types
    .model('Auth', {
        user: types.maybeNull(User),
        atolIdUser: types.maybeNull(AtolIdUser),
        isLoading: types.optional(types.boolean, false),
        isIdAuth: types.optional(types.boolean, false),
        entryPoint: types.maybeNull(types.string),
        permissions: types.maybeNull(types.array(Permission)),
    })
    .views(() => ({
        get mainPageUrl(): string {
            return '/';
        },
    }))
    .actions((self) => {
        const auth = self;

        const keycloak = new KeycloakService();

        const getPermissions = flow(function* ajax() {
            auth.isLoading = true;
            try {
                const permissions = yield api.get({path: '/auth/permissions'});
                auth.permissions = permissions;
            } catch (err) {
                console.error(err);
            }
            auth.isLoading = false;
        });

        const init = flow(function* ajax() {
            auth.isLoading = true;
            const isAuth = yield keycloak
                .init()
                .then((authenticated: boolean) => {
                    return (authenticated);
                })
                .catch((error) => {
                    return (error);
                });
            if (isAuth) {
                yield getPermissions();
                yield afterIdCreate();
                auth.isIdAuth = true;
            } else {
                auth.isIdAuth = false;
                history.push('/auth');
            }
            auth.isLoading = false;
        });

        // eslint-disable-next-line require-yield
        const afterIdCreate = flow(function* ajax() {
            auth.isLoading = true;
            try {
                const {email, name} = keycloak.getUser();
                auth.atolIdUser = {
                    name,
                    email,
                };
            } catch (err) {
                auth.atolIdUser = null;
            }

            auth.isLoading = false;
        });

        const atolIdLogin = async (): Promise<void> => {
            try {
                await keycloak.login();
            } catch (error) {
                console.error('Failed to handle login: ', error);
            }
        };

        const atolIdLogout = async (): Promise<void> => {
            try {
                await keycloak.logout();
            } catch (error) {
                console.error('Failed to handle logout: ', error);
            }
        };

        const refreshToken = async (): Promise<void> => {
            try {
                await keycloak?.refreshToken();
            } catch (error) {
                console.error('Failed to handle logout: ', error);
            }
        };

        return {
            init,
            atolIdLogout,
            atolIdLogin,
            refreshToken,
        };
    });

export default Auth;

export type IAuth = Instance<typeof Auth>
export type IAuthSnapshotIn = SnapshotIn<typeof Auth>
export type IAuthSnapshotOut = SnapshotOut<typeof Auth>
