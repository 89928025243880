import {types} from 'mobx-state-tree';

// import UpdateFirmware from './updateFirmware';
import Operation from './operation';

interface Operations {
    create(operationDefaults: {}): unknown;
    name: string
}

const operations: Record<string, Operations> = {

};

[
    // UpdateFirmware,
].forEach((item) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
    operations[item.name] = types.compose(Operation, item).named(item.name);
});

export default operations;
