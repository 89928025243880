/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// @ts-nocheck
import {types, flow, getRoot} from 'mobx-state-tree';
import api from 'api';
import Form from 'atol-smart/models/form';
import {DEVICE_COMMENT_MAX_SIZE} from 'constants/inputSize';

const CommentModal = types
    .model('CommentModal', {
        form: types.optional(Form, {
            fields: [
                {
                    name: 'comment',
                    validation: [
                        {
                            test: (value: string) => value.length <= DEVICE_COMMENT_MAX_SIZE,
                            message: `Максимальное количество символов ${DEVICE_COMMENT_MAX_SIZE}`,
                        },
                    ],
                },
            ],
        }),
        isLoading: types.optional(types.boolean, false),
        error: types.optional(types.maybeNull(types.string), null),
    }).actions((self) => {
        const model = self;
        const setError = getRoot(self).apiError.set as (code: number, message: string)=> void;
        const refreshToken = getRoot(self).auth.refreshToken as ()=> void;

        const saveComment = flow(function* ajax(serialNumber: string) {
            model.isLoading = true;
            model.error = null;
            const comment = model.form.field.comment.value as string;
            try {
                yield api.put({path: `/device?serial_number=${serialNumber}${comment ? '&comment=' + comment.replace(/\n/g, ' ') : ''}`,
                    setError,
                    refreshToken});
                model.isLoading = false;
            } catch (err) {
                model.error = err.message as string;
                model.isLoading = false;
                throw new Error();
            }

            model.isLoading = false;
        });

        const clearError = () => {
            model.error = null;
        };

        const clearCommentField = () => {
            model.form.field.comment.value = undefined;
        };

        return {saveComment, clearError, clearCommentField};
    });

export default CommentModal;
