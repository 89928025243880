/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-implied-eval */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// @ts-nocheck

import {types} from 'mobx-state-tree';
import GroupFilterItem from './groupFilterItem';

const GroupFilter = types
    .model('GroupFilter', {
        id: types.optional(types.string, ''),
        list: types.array(GroupFilterItem),
        name: types.optional(types.string, ''),
        type: types.string,
    })
    .actions((self) => {
        const groupFilter = self;
        const toggleByValue = (value, e = true, label = '') => {
            const state = e.target ? e.target.value : e;
            const filterItem = groupFilter.list.find((item) => {
                return (item.value).toString() === (value).toString();
            });
            if (filterItem) {
                filterItem.set(state);
                filterItem.add(state);
            } else {
                groupFilter.list.push({
                    label,
                    value,
                    selected: state,
                    active: state,
                });
            }
        };

        const assignFilters = (list) => {
            const existingFilters = {};
            if (groupFilter.list.length > 0) {
                groupFilter.list.forEach((item) => existingFilters[item.value] = item.selected);
            }
            groupFilter.list = groupFilter.list.length === 0
                ? list
                : list.map((item) => ({...item, selected: existingFilters[item.value] || false}));
        };

        const toggleAll = () => {
            const state = !groupFilter.isAllSelected;
            groupFilter.list.forEach((item) => item.set(state));
        };

        const changeTabReset = () => {
            groupFilter.list.forEach((item) => {
                item.set(false);
            });
        };

        const resetSelected = () => {
            groupFilter.list.forEach((item) => {
                if (item.selected && !item.active) {
                    item.set(false);
                }
                if (!item.selected && item.active) {
                    item.set(true);
                }
            });
        };
        const toggleFilters = (filterItem) => {
            filterItem.set(!filterItem.selected);

            const updateParents = (item) => {
                const parent = groupFilter.list.find((parentItem) => parentItem.value === item.parentId);
                if (parent) {
                    const siblings = groupFilter.list.filter((sibling) => sibling.parentId === parent.value);
                    const allSiblingsSelected = siblings.every((sibling) => sibling.selected);

                    parent.selected = allSiblingsSelected;
                    updateParents(parent); // Рекурсивно обновляем всех родителей
                }
            };

            const updateChildren = (item) => {
                const children = groupFilter.list.filter((child) => child.parentId === item.value);
                children.forEach((child) => {
                    child.set(item.selected);
                    updateChildren(child); // Рекурсивно обновляем все дочерние элементы
                });
            };

            updateChildren(filterItem);
            updateParents(filterItem);
        };

        const setFilters = () => {
            groupFilter.list.forEach((item) => {
                if (item.selected) {
                    item.add(true);
                } else {
                    item.add(false);
                }
            });
        };

        const reset = () => {
            groupFilter.list.forEach((item) => {
                item.set(false);
                item.add(false);
            });
        };

        return {
            toggleAll,
            toggleByValue,
            assignFilters,
            reset,
            toggleFilters,
            setFilters,
            resetSelected,
            changeTabReset,
        };
    })
    .views((groupFilter) => ({
        get isAllSelected() {
            return groupFilter.list.length === 0 ? false : !groupFilter.list.some((item) => item.selected === false);
        },
        get isAllActive() {
            return groupFilter.list.length === 0 ? false : !groupFilter.list.some((item) => item.active === false);
        },
        get isAnySelected() {
            return groupFilter.list.some((item) => item.selected === true);
        },
        get isAnyGroupActive() {
            return groupFilter.list.some((item) => item.active === true && item.isUserGroup);
        },
        get isAnyActive() {
            return groupFilter.list.some((item) => {
                return item.active === true;
            });
        },
        get queryString() {
            if (groupFilter.type === 'boolean' && groupFilter.isAllActive) return '';
            return groupFilter.list
                .filter(({active}) => active)
                .map(({value}) => `${groupFilter.name}=${value}`)
                .join('&');
        },
    }));

export default GroupFilter;
