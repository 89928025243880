/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// @ts-nocheck
import {keysToCamel} from 'atol-atoms/tools';
import {getDefaultApi} from 'configs';

const API_URL = getDefaultApi();

const getApiUrl = (path: string): string => `${API_URL}${path}`;

type TRespFormat = 'blob' | 'json';

interface IError {
    code: string;
    message: string;
}

type TSetError = (code: number, message?: string | null)=> void;

function handleResponse<T>(response: Response, responseFormat?: TRespFormat): Promise<T> {
    if (responseFormat === 'blob') {
        return response.blob().then((blob) => {
            if (!response.ok) {
                return Promise.reject(new Error(JSON.stringify({code: response.status, message: blob?.message})));
            }

            const fileName = response.headers.get('content-disposition')
                ? response.headers.get('content-disposition').split('"')[1]
                : 'devices.xls';

            return {blob, fileName} as T;
        });
    }

    return response.text().then((text) => {
        const data: T | Error = JSON.parse(text);
        if (!response.ok) {
            return Promise.reject(new Error(JSON.stringify({code: response.status, message: data?.message || data?.detail?.[0].msg})));
        }

        return keysToCamel(data) as T;
    });
}

const globalErrors = [400, 401, 402, 403, 500, 503];

function handleError(error: Error, setGlobalError?: TSetError): Promise<Error> {
    const errorObj: IError = JSON.parse(error.message);
    const errorCode = Number(errorObj.code);

    if (globalErrors.some((el) => el === errorCode)) {
        if (typeof setError === 'function') setGlobalError(errorCode);
    }

    return Promise.reject(error);
}

interface IGet {
    path: string,
    responseFormat?: TRespFormat,
    setGlobalError?: TSetError,
    refreshToken?: ()=> void,
}

async function get<T>({path, responseFormat, setGlobalError, refreshToken}: IGet): Promise<T | Error> {
    if (refreshToken) refreshToken();

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    };

    // try {
    //     const response = await fetch(getApiUrl(path), requestOptions);
    //     if (!response.ok) {
    //         if (response.status === 422) {
    //             const errorData = await response.json();
    //             // eslint-disable-next-line prefer-promise-reject-errors, @typescript-eslint/no-unsafe-member-access
    //             return Promise.reject({error: errorData, message: errorData.detail[0]?.msg});
    //         }
    //         // eslint-disable-next-line prefer-promise-reject-errors
    //         return Promise.reject('На сервере произошла ошибка, попробуйте позднее');
    //     }
    //     const data = response;
    //     return await handleResponse<T>(data, responseFormat);
    // } catch (error: Error) {
    //     return {error};
    // }

    return fetch(getApiUrl(path), requestOptions)
        .then((data) => handleResponse<T>(data, responseFormat))
        .catch((error: Error) => handleError(error, setGlobalError));
}

interface IPost {
    path: string,
    body: object,
    setGlobalError?: TSetError,
    refreshToken?: ()=> void,
}

function post<T>({path, body, setGlobalError, refreshToken}: IPost): Promise<T | Error> {
    if (refreshToken) refreshToken();

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(body),
    };

    return fetch(getApiUrl(path), requestOptions)
        .then(handleResponse<T>)
        .catch((error: Error) => handleError(error, setGlobalError));
}

interface IPut {
    path: string,
    body: object,
    setGlobalError?: TSetError,
    refreshToken?: ()=> void,
}

function put<T>({path, body, setGlobalError, refreshToken}: IPut): Promise<T | Error> {
    if (refreshToken) refreshToken();

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(body),
    };

    return fetch(getApiUrl(path), requestOptions)
        .then(handleResponse<T>)
        .catch((error: Error) => handleError(error, setGlobalError));
}

interface IDelete {
    path: string,
    setGlobalError?: TSetError,
    refreshToken?: ()=> void,
}

function _delete<T>({path, setGlobalError, refreshToken}: IDelete): Promise<T | Error> {
    if (refreshToken) refreshToken();

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    };
    return fetch(getApiUrl(path), requestOptions)
        .then(handleResponse<T>)
        .catch((error: Error) => handleError(error, setGlobalError));
}

export default {
    get,
    post,
    put,
    delete: _delete,
};
