import connect from 'tools/connect';
import Error, {TErrorType} from 'containers/Error';
import ErrorBoundary from 'components/ErrorBoundary';
import Wizard from 'containers/Wizard';
import {useState} from 'react';
import ClientCabinet from './ClientCabinet';
import LeftPanel from './LeftPanel';
import TopPanel from './TopPanel';
import './style.scss';

interface ICabinetContainer {
    code: TErrorType;
}

function CabinetContainer({code}: ICabinetContainer) {
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const CentralComponent = code ? <Error type={code} /> : <ClientCabinet />;

    return (
        <div className="CabinetContainer">
            <LeftPanel
                isMenuVisible={isMenuVisible}
                setIsMenuVisible={setIsMenuVisible}
            />
            <div className="CabinetContent">
                <TopPanel
                    setIsMenuVisible={setIsMenuVisible}
                    isMenuVisible={isMenuVisible}
                />
                <div className="CabinetContent__body">
                    <ErrorBoundary>
                        {CentralComponent}
                        <Wizard />
                    </ErrorBoundary>
                </div>
            </div>
        </div>
    );
}

export default connect(({
    apiError: {
        code,
    },
}) => ({
    code,
}))(CabinetContainer);
