import {types} from 'mobx-state-tree';

import NetworkStore from './network';
import BaseStore from './base';
import ApplicationsStore from './applications';
import AdditionalStore from './additional';
import ConfigurationsStore from './configurations';

const DeviceDetails = types
    .model('DeviceDetails', {
        base: types.optional(BaseStore, {}),
        network: types.optional(NetworkStore, {}),
        applications: types.optional(ApplicationsStore, {}),
        additional: types.optional(AdditionalStore, {}),
        configurations: types.optional(ConfigurationsStore, {}),
    });

export default DeviceDetails;
