/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// @ts-nocheck

import {types, flow, getRoot} from 'mobx-state-tree';
import api from 'api';
import List from 'components/Table/list';

import DeviceItem from './deviceItem';
import ListFile from './listFile';

const DevicesList = types
    .model('DevicesList', {
        list: types.optional(List(DeviceItem), {
            searchField: 'quick_search',
            filters: [
                {
                    id: 'version',
                    name: 'os_version__in',
                },
                {
                    id: 'product',
                    name: 'product_name__in',
                },
                {
                    id: 'modelType',
                    name: 'device_model_type_alias__in',
                },
                {
                    id: 'organization',
                    name: 'organization_name__in',
                },
            ],
        }),
        listFile: types.optional(ListFile, {}),
        error: types.optional(types.frozen(), null),
    })
    .actions((self) => {
        const model = self;
        const setError = getRoot(self).apiError.set as (code: number, message: string)=> void;
        const refreshToken = getRoot(self).auth.refreshToken as ()=> void;

        const getList = flow(function* ajax() {
            model.list.isLoading = true;
            try {
                const data = yield api.get({path: `/devices?${model?.list?.queryString as string}`, responseFormat: 'json', setError, refreshToken});
                model.list.items = data.data;
                model.list.count = data.count;
            } catch (err) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                try {
                    const error = JSON.parse(err.message);
                    model.list.setError(error.message);
                    model.error = error;
                } catch (e) {
                    model.error = {code: '502', message: 'Перезагрузите страницу или повторите попытку позже'};
                    model.list.setError('Перезагрузите страницу или повторите попытку позже');
                }
            }
            model.list.isLoading = false;
        });

        // Filters -----------------------------------
        const getVersions = flow(function* ajax() {
            try {
                const data = yield api.get({path: '/devices/filter_os_versions', refreshToken});
                model.list.filter.version.assignFilters(data.map((el: string) => ({label: el || 'Отсутствует', value: el})));
            } catch (err) {
                console.error(err);
            }
        });

        const getProducts = flow(function* ajax() {
            try {
                const data = yield api.get({path: '/devices/filter_product_names', refreshToken});
                model.list.filter.product.assignFilters(data.map((el: string) => ({label: el, value: el})));
            } catch (err) {
                console.error(err);
            }
        });

        const getModelTypes = flow(function* ajax() {
            try {
                const data = yield api.get({path: '/devices/filter_device_model_types', refreshToken});
                model.list.filter.modelType.assignFilters(data.map((el: string) => ({label: el, value: el})));
            } catch (err) {
                console.error(err);
            }
        });

        const getOrganizations = flow(function* ajax() {
            try {
                const data = yield api.get({path: '/devices/filter_organizations', refreshToken});
                model.list.filter.organization.assignFilters(data.map((el: string) => ({label: el, value: el})));
            } catch (err) {
                console.error(err);
            }
        });
        // ----------------------------------- Filters

        const init = flow(function* ajax() {
            model.list.items = [];
            model.list.isLoading = true;

            yield Promise.all([
                getVersions(),
                getProducts(),
                getModelTypes(),
                getOrganizations(),
            ]);

            model.list.isLoading = false;
            model.list.attach();
        });

        const resetListError = () => {
            model.error = null;
            model.list.resetListError();
        };

        return {getList, init, resetListError};
    });
export default DevicesList;
