/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-implied-eval */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// @ts-nocheck

import {types} from 'mobx-state-tree';
import FilterItem from './filterItem';

const Filter = types
    .model('Filter', {
        id: types.optional(types.string, ''),
        list: types.array(FilterItem),
        name: types.optional(types.string, ''),
        type: types.optional(
            types.enumeration('Type', ['list', 'boolean']),
            'list',
        ),
    })
    .actions((self) => {
        const filter = self;
        const toggleByValue = (value, e = true, label = '') => {
            const state = e.target ? e.target.value : e;
            const filterItem = filter.list.find((item) => {
                return (item.value).toString() === (value).toString();
            });
            if (filterItem) {
                filterItem.set(state);
                filterItem.add(state);
            } else {
                filter.list.push({
                    value,
                    active: state,
                    selected: state,
                    label,
                });
            }
        };

        const assignFilters = (list) => {
            const existingFilters = {};
            if (filter.list.length > 0) {
                filter.list.forEach((item) => existingFilters[item.value] = item.selected);
            }
            filter.list = filter.list.length === 0 ? list : list.map((item) => ({...item, selected: existingFilters[item.value] || false}));
        };

        const toggleAll = () => {
            const state = !filter.isAllSelected;
            filter.list.forEach((item) => item.set(state));
        };

        const resetSelected = () => {
            filter.list.forEach((item) => {
                if (item.selected && !item.active) {
                    item.set(false);
                }
            });
        };

        const setFilters = () => {
            filter.list.forEach((item) => {
                if (item.selected) {
                    item.add(true);
                } else {
                    item.add(false);
                }
            });
        };

        const reset = () => {
            filter.list.forEach((item) => {
                item.set(false);
                item.add(false);
            });
        };

        return {toggleAll, toggleByValue, assignFilters, reset, setFilters, resetSelected};
    })
    .views((filter) => ({
        get isAllSelected() {
            return filter.list.length === 0 ? false : !filter.list.some((item) => item.selected === false);
        },
        get isAllActive() {
            return filter.list.length === 0 ? false : !filter.list.some((item) => item.active === false);
        },
        get isAnySelected() {
            return filter.list.some((item) => item.selected === true);
        },
        get isAnyActive() {
            return filter.list.some((item) => {
                return item.active === true;
            });
        },
        get queryString() {
            if (filter.type === 'boolean' && filter.isAllActive) return '';
            return filter.list
                .filter(({active}) => active)
                .map(({value}) => `${filter.name}=${value}`)
                .join('&');
        },
    }));

export default Filter;
