/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-implied-eval */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// @ts-nocheck
import {types} from 'mobx-state-tree';

const QuickSearch = types
    .model('QuickSearch', {
        searchString: types.optional(types.string, ''),
    })
    .volatile(() => ({
        onSearch: types.function,
        searchTimeout: types.frozen(null),
    }))
    .actions((self) => {
        const quickSearch = self;

        const changeSearchString = (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e === null ? '' : e;
            quickSearch.searchString = value.target ? value.target.value : value;
            clearTimeout(quickSearch.searchTimeout);
            // eslint-disable-next-line @typescript-eslint/no-implied-eval, @typescript-eslint/no-unsafe-argument
            quickSearch.searchTimeout = setTimeout(quickSearch.search, 500);
        };

        const setOnSearch = (callback) => (quickSearch.onSearch = callback);

        const search = () => {
            if (typeof quickSearch.onSearch === 'function') quickSearch.onSearch(quickSearch.searchString);
        };

        const reset = () => {
            quickSearch.searchString = '';
            quickSearch.onSearch = null;
        };

        return {changeSearchString, setOnSearch, search, reset};
    });
export default QuickSearch;
