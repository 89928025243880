import {Instance, types} from 'mobx-state-tree';
import Devices from './devices';

const Pages = types.model('Pages', {
    devices: types.optional(Devices, {}),
});

export default Pages;

export type IPages = Instance<typeof Pages>;
