export interface CustomWindowConfigWithStore extends Window {
    store?: any;
}

export type Environment = 'development' | 'staging' | 'production' | 'test';

interface ProcessEnv {
    REACT_APP_ENVIRONMENT: Environment;
  }

type Defaults = {
    [key in Environment]: {
        api: string,
        newAppBaseUrl: string,
        sentryUrl: string,
        onPremise: boolean,
        isFfdFirmwareUpdateDisabled: boolean,
        keycloakApi: string
    };
};
interface CustomWindowConfig extends Window {
    __$config?: {
        SENTRY_URL?: string,
        NEW_APP_BASE_URL?: string,
        IS_FFD_FIRMWARE_UPDATE_DISABLED?: string,
        ENVIRONMENT?: Environment,
        API?: string,
        ON_PREMISE?: boolean,
        KEYCLOAK_API?: string
    };
}
interface CustomWindowOnPremiseConfig extends Window {
    __$onPremiseConfig?: any;
}

interface Config {
    api: string,
    keycloakApi: string,
    newAppBaseUrl: string,
    sentryUrl: string,
    isProduction: boolean,
    onPremise: boolean | string,
    isFfdFirmwareUpdateDisabled: boolean | string;
}

if (!(window as CustomWindowConfig).__$config) (window as CustomWindowConfig).__$config = {};
if (!(window as CustomWindowOnPremiseConfig).__$onPremiseConfig) (window as CustomWindowOnPremiseConfig).__$onPremiseConfig = {};

const defaults: Defaults = {
    development: {
        api: 'https://core-tms-tms-core-master.dev.vmw.ac.atol.tech',
        newAppBaseUrl: '/',
        sentryUrl: 'https://a9ca8f223e204c318b4d44d51e80c649@sentry.atol.ru/162',
        onPremise: false,
        isFfdFirmwareUpdateDisabled: false,
        keycloakApi: 'https://id.atol.tech/auth',
    },
    staging: {
        api: 'https://ac.atol.tech/',
        newAppBaseUrl: '/',
        sentryUrl: 'https://7d8378b2639c4374bd19a682594b8012@sentry.atol.ru/163',
        onPremise: false,
        isFfdFirmwareUpdateDisabled: false,
        keycloakApi: 'https://rc-id.atol.tech/auth',
    },
    production: {
        api: 'https://tms.atol.ru/api',
        newAppBaseUrl: '/',
        sentryUrl: 'https://0be472f38ec64a808b5f36fb6964a969@sentry.atol.ru/161',
        onPremise: false,
        isFfdFirmwareUpdateDisabled: false,
        keycloakApi: 'https://id.atol.ru/auth',
    },
    test: {
        api: 'test',
        newAppBaseUrl: '/',
        sentryUrl: '',
        onPremise: false,
        isFfdFirmwareUpdateDisabled: false,
        keycloakApi: 'https://rc-id.atol.tech/auth',
    },
};
export const environment: Environment = (window as CustomWindowConfig).__$config?.ENVIRONMENT
    || (process.env.REACT_APP_ENVIRONMENT as Environment)
    || 'development';

export const getDefaultApi = () => {
    return (window as CustomWindowConfig).__$config?.API || process.env.REACT_APP_API || defaults[environment].api;
};

export const isOnPremise = (window as CustomWindowConfig).__$config?.ON_PREMISE
    || process.env.REACT_APP_ON_PREMISE
    || defaults[environment].onPremise;

export const getKeycloakApi = (): string => (window as CustomWindowConfig).__$config.KEYCLOAK_API
    || process.env.KEYCLOAK_API
    || defaults[environment].keycloakApi;

const isFfdFirmwareUpdateDisabledConverted: boolean = (window as CustomWindowConfig).__$config
    .IS_FFD_FIRMWARE_UPDATE_DISABLED
    ? Boolean(
        Number.parseInt(
            (window as CustomWindowConfig).__$config.IS_FFD_FIRMWARE_UPDATE_DISABLED,
            10,
        ),
    )
    : false;

const config: Config = {
    api: localStorage.getItem('as-api') || getDefaultApi(),
    keycloakApi: getKeycloakApi(),
    newAppBaseUrl:
        (window as CustomWindowConfig).__$config.NEW_APP_BASE_URL
        || process.env.REACT_APP_NEW_APP_BASE_URL
        || defaults[environment].newAppBaseUrl,
    sentryUrl:
        (window as CustomWindowConfig).__$config.SENTRY_URL
        || process.env.REACT_APP_SENTRY_URL
        || defaults[environment].sentryUrl,
    isProduction: environment === 'production',
    onPremise: isOnPremise,
    isFfdFirmwareUpdateDisabled:
        isFfdFirmwareUpdateDisabledConverted
        || process.env.REACT_APP_IS_FFD_FIRMWARE_UPDATE_DISABLED
        || defaults[environment].isFfdFirmwareUpdateDisabled,
};

export default config;

(window as CustomWindowOnPremiseConfig).__$onPremiseConfig = {};
(window as CustomWindowConfig).__$config = {};
