import {types} from 'mobx-state-tree';
import DevicesList from './devicesList';
import DeviceDetails from './deviceDetails';

const Devices = types.model('Devices', {
    devicesList: types.optional(DevicesList, {}),
    deviceDetails: types.optional(DeviceDetails, {}),
});

export default Devices;
