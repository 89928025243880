import {types} from 'mobx-state-tree';

export const ApiError = types
    .model('ApiError', {
        code: types.maybeNull(types.number || types.string),
        message: types.maybeNull(types.string),
    }).actions((self) => {
        const model = self;

        const set = (code: string | number | null, message: string | null) => {
            model.code = code;
            model.message = message;
        };

        const clear = () => {
            set(null, null);
        };
    
        return {set, clear};
    });

export default ApiError;
