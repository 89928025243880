/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
// TODO: typescript
import {types, getRoot, flow, Instance} from 'mobx-state-tree';
import {notEmpty} from 'tools/validators';
import config, {getDefaultApi} from 'configs';
import Form from 'atol-smart/models/form';
import {AuthProps, IAuth} from 'stores/auth';
import {FormType} from 'stores/form/types';
import {IStore} from '..';

export interface IDevSettingsState {
    devSettings: {
        form: {
          field: {
            backendUrl: FormType
          },
        },
        isOpen: boolean,
        isSaving: boolean,
        closeSettings: ()=> void,
        openSettings: ()=> void,
        saveSettings: ()=> void,
        resetApiUrl: ()=> void,
    }
  }
export interface IDevSettingsStateFromCommon {
    common: {
        devSettings: {
            IDevSettingsState
        }
    }
}

const DevSettings = types
    .model('DevSettings', {
        form: types.optional(Form, {
            fields: [
                {
                    name: 'backendUrl',
                    validation: [
                        {
                            test: notEmpty,
                            message: 'Поле обязательно для заполнения',
                        },
                    ],
                },
            ],
        }),
        isOpen: types.optional(types.boolean, false),
        isSaving: types.optional(types.boolean, false),
    })
    .actions((self) => {
        const model = self;
        const {addNotification} = getRoot<IStore>(self).common;
        const openSettings = () => {
            model.form.field.backendUrl.onChange(config.api);
            model.isOpen = true;
        };
        const closeSettings = () => {
            model.isOpen = false;
        };
        const saveSettings = flow(function* ajax() {
            model.form.setIsErrorShown(true);
            if (!model.form.isValid) return;

            try {
                model.isSaving = true;
                if (model.form.field.backendUrl.value === getDefaultApi()) {
                    localStorage.removeItem('as-admin-api');
                } else {
                    const request = yield fetch(model.form.field.backendUrl.value);
                    const text = yield request.text();
                    if (!text.startsWith('Time:')) {
                        throw new Error('Не удалось подключиться к бекенду');
                    }
                    localStorage.setItem('as-admin-api', model.form.field.backendUrl.value);
                }
                config.api = model.form.field.backendUrl.value;
                getRoot<AuthProps>(model).auth.checkUser();
                model.isOpen = false;
            } catch (err) {
                addNotification(err);
            }
            model.isSaving = false;
        });

        const resetApiUrl = () => {
            localStorage.removeItem('as-admin-api');
            model.form.field.backendUrl.onChange(getDefaultApi());
        };

        return {openSettings, closeSettings, resetApiUrl, saveSettings};
    });

export default DevSettings;

export type IDevSettings = Instance<typeof DevSettings>
