import {ErrorBoundary as SentryErrorBoundary, withScope} from '@sentry/react';
import Typography from 'atol-atoms/Typography';
import {ReactNode} from 'react';
import {getStore} from 'tools/connect';
import './style.scss';

function ErrorBoundaryMessage() {
    return (
        <div className="ErrorBoundary">
            <Typography type="strong">Упс, что-то пошло не так...</Typography>
        </div>
    );
}
interface ErrorBoundaryProps {
    children: ReactNode,
    silent?: boolean,
}

export default function ErrorBoundary({children, silent = false}: ErrorBoundaryProps) {
    return (
        <SentryErrorBoundary
            onError={(error: globalThis.Error) => {
                const isChankLoadingError = /(Loading)(.*)(failed)/.test(
                    error.stack,
                );
                if (isChankLoadingError) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                    getStore().saveState();
                    window.location.reload();
                }
            }}
            beforeCapture={() => {
                withScope((scope) => {
                    scope.setTag('crash-type', 'render');
                });
            }}
            fallback={!silent && <ErrorBoundaryMessage />}
        >
            {children}
        </SentryErrorBoundary>
    );
}
