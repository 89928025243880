/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {types, applySnapshot, IStateTreeNode, Instance} from 'mobx-state-tree';
import auth, {IAuth} from './auth';
import Pages, {IPages} from './pages';
import {IWizard} from './wizard';
import Wizard from './wizard/Wizard';
import Common, {ICommon} from './common';
import Experiments from './experiments';
import ApiError from './apiError';

interface StoreType {
    auth: IAuth,
    common: ICommon,
    pages: IPages,
    wizard: IWizard
}

const Store = types
    .model('Store', {
        auth: types.optional(auth, {}),
        common: types.optional(Common, {}),
        pages: types.optional(Pages, {}),
        wizard: types.optional(Wizard, {}),
        experiments: types.optional(Experiments, {}),
        apiError: types.optional(ApiError, {}),
    })
    .actions((self) => {
        const store: StoreType & IStateTreeNode<typeof Store> = self;

        const reset = () => {
            applySnapshot(store.wizard, {});
            applySnapshot(store.pages, {});
        };

        const isStateValid = (state): state is { wizard: IWizard; pages: IPages } => {
            return (
                typeof state === 'object'
              && 'wizard' in state
              && 'pages' in state
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              && typeof state.wizard === 'object'
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              && typeof state.pages === 'object'
            );
        };

        const afterCreate = () => {
            const localStorageState = localStorage.getItem('storeState');
            if (!/({)(.*)(})/.test(localStorageState)) return;
            localStorage.removeItem('storeState');
            const state = JSON.parse(localStorageState) as { wizard: IWizard; pages: IPages };

            if (!isStateValid(state)) {
                return;
            }
            const {wizard, pages} = state as { wizard: IWizard; pages: IPages };
            applySnapshot(store.wizard, wizard);
            applySnapshot(store.pages, pages);
        };

        const saveState = () => {
            localStorage.setItem('storeState', JSON.stringify(store));
        };
        return {reset, saveState, afterCreate};
    });

export default Store;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IStore extends Instance<typeof Store> {}
