/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import {types, getRoot} from 'mobx-state-tree';
// import ym from 'react-yandex-metrika';
import EXPERIMENTS from 'constants/experiments';

const Experiments = types
    .model('Experiments', {
    })
    .actions((self) => {
        const model = self;

        const setVisitParams = () => {
            const visitParams = {};
            if (model.hasExperiments) {
                Object.keys(EXPERIMENTS).forEach((experiment) => {
                    visitParams[experiment] = model.getUserExperimentGroup(experiment);
                });
                // ym('params', visitParams);
            }
        };
        const reachExperimentGoal = (experiment: string) => {
            // ym('reachGoal', experiment);
        };
        return {
            setVisitParams,
            reachExperimentGoal,
        };
    })
    .views((model) => ({
        get hasExperiments() {
            return (Object.keys(EXPERIMENTS).length > 0);
        },
        getUserExperimentGroup(experiment) {
            const {groups, salt} = EXPERIMENTS[experiment];
            const user = getRoot(model).auth?.user;
            return groups[(user?.id + salt) % groups.length];
        },
    }));

export default Experiments;
