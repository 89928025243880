import {types} from 'mobx-state-tree';

const TopPanel = types.model('Notification', {
    title: types.optional(types.maybeNull(types.string), ''),
}).actions((self) => {
    const model = self;

    const setTitle = (title: string) => {
        model.title = title;
    };

    return {setTitle};
});

export default TopPanel;
