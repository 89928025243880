import Loader from 'atol-atoms/Loader';
import {ROUTER_SCHEME} from 'constants/routes';
import Error from 'containers/Error';
import {lazy, LazyExoticComponent, Suspense} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import useScrollToTop from 'tools/useScrollToTop';

const StartPage = lazy(() => import('../../StartPage'));
const Devices = lazy(() => import('../../Devices'));
const Profile = lazy(() => import('../../Profile'));
const DeviceDetails = lazy(() => import('../../Devices/DevicesDetails'));

const containers = {
    StartPage,
    DeviceDetails,
    Devices,
    Profile,
};

export default function ClientCabinet() {
    useScrollToTop();
    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                <Route exact path="/">
                    <Redirect to="devices/" />
                </Route>
                {Object.keys(ROUTER_SCHEME)
                    .filter((path) => ROUTER_SCHEME[path].container)
                    .map((path) => {
                        const Container = containers[ROUTER_SCHEME[path].container] as LazyExoticComponent<()=> JSX.Element>;
                        return (
                            <Route
                                path={path}
                                key={path}
                            >
                                <Container />
                            </Route>
                        );
                    })}
                <Route path="*">
                    <Error type="404" />
                </Route>
            </Switch>
        </Suspense>
    );
}
