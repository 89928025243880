import Notifications from 'atol-atoms/Notifications';
import {NotificationManagerProps} from 'stores/common/notification';
import connect from 'tools/connect';

function NotificationManager({notifications, updateNotification}: NotificationManagerProps) {
    return (
        <Notifications
            type="dark"
            delay={4000}
            onClose={updateNotification}
            notifications={[...notifications]}
            // classname="NotificationsManager"
        />
    );
}
export default connect(({common: {notifications, updateNotification}}) => ({
    notifications,
    updateNotification,
}))(NotificationManager);
