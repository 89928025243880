import {types} from 'mobx-state-tree';

const FilterItem = types
    .model('FilterItem', {
        label: types.string,
        value: types.union(types.string, types.number, types.boolean),
        selected: types.optional(types.boolean, false),
        active: types.optional(types.boolean, false),
    })
    .actions((self) => {
        const filterItem = self;
        const toggle = () => {
            const state = !filterItem.selected;
            filterItem.selected = state;
        };

        const set = (state: boolean) => {
            filterItem.selected = state;
        };

        const add = (state: boolean) => {
            filterItem.active = state;
        };

        return {toggle, set, add};
    });

export default FilterItem;
