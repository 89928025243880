/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
// @ts-ignore
import {Observer} from 'mobx-react-lite';
import {ComponentType} from 'react';
import {IStore} from 'stores/index';

let store: IStore | null = null;

export const setStore = (newStore: IStore) => {
    store = newStore;
};

type SelectorType = (store: IStore, ownProps: any)=> any;

const connect = (selector: SelectorType) => (baseComponent: ComponentType<any>) => {
    const component: React.FC<any> = (ownProps) => {
        if (store === null) throw new Error('Please, use setStore before your ReactDOM.render call');
        return (
            <Observer>
                {() => baseComponent({...ownProps, ...selector(store, ownProps)})}
            </Observer>
        );
    };
    component.displayName = baseComponent.name;
    return component;
};

export default connect;
export const getStore = () => store;
